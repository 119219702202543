// React Query
import { useMutation, useQuery } from 'react-query';

// Yup
import * as Yup from 'yup';

// Formik
import { Form, Formik } from 'formik';

// API

// Moment
import moment from 'moment';

// Hot Toast
import { toast } from 'react-hot-toast';

// Interfaces

// Utils

// Material UI
import {
  Alert,
  Autocomplete,
  Box,
  Divider,
  Grid,
  LinearProgress,
  TextareaAutosize,
  TextField
} from '@mui/material';
import { DateTimePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import UpdateIcon from '@mui/icons-material/Update';

import {
  getErrorMessage,
  sortAlphabetically
} from '../../../../../utils/utils';
import {
  ILearningPath,
  ILearningPaths
} from '../../../../../interfaces/learningPaths.interface';
import { GET, PATCH } from '../../../../../services/api/api';

const GeneralDataSchema = Yup.object().shape({
  name: Yup.string()
    .min(5, 'Muy pequeño!')
    .max(500, 'Mucho texto!')
    .required('Requerido'),

  youtube_video_id: Yup.string(),
  call_out: Yup.string(),
  title: Yup.string().min(5, 'Muy pequeño!').max(500, 'Mucho texto!'),
  description: Yup.string().min(5, 'Muy pequeño!').max(500, 'Mucho texto!'),
  seo_meta_description: Yup.string()
    .min(5, 'Muy pequeño!')
    .max(255, 'Mucho texto!'),

  published_at: Yup.date().nullable(true),
  archived_at: Yup.date()
    .nullable(true)
    .min(Yup.ref('published_at'), 'ARCHIVADO NO puede ser antes que PUBLICADO'),

  next_learning_path: Yup.number()
});

interface Props {
  refetch: () => {};
  learningPathId: number;
}

const GeneralData: React.FC<Props> = ({ refetch, learningPathId }) => {
  const {
    data: learningPath,
    error: errorLearningPath,
    isError: isErrorLearningPath,
    isLoading: isLoadingLearningPath,
    remove
  } = useQuery(
    ['learningPath-general-data', learningPathId],
    () => GET<ILearningPath>(`/learning_paths_backoffice/${learningPathId}/`),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const { data: learningPaths, isLoading: isLoadingLearningPaths } = useQuery(
    ['learning-paths'],
    () => GET<ILearningPaths>(`/learning-paths/`),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const editLearningPathGeneralData = useMutation(
    (learningPath: any) =>
      PATCH(`/learning_paths_backoffice/${learningPathId}/`, learningPath),
    { retry: false }
  );

  if (
    isLoadingLearningPath ||
    isLoadingLearningPaths ||
    !learningPath ||
    !learningPaths
  )
    return <LinearProgress />;
  if (isErrorLearningPath)
    return <div>Error! {JSON.stringify(errorLearningPath)}</div>;

  const initialValues = {
    name: learningPath.name,
    slug: learningPath.slug,

    youtube_video_id: learningPath.youtube_video_id,
    call_out: learningPath.call_out,

    title: learningPath.title,
    description: learningPath.description,

    seo_title: learningPath.seo_title,
    seo_meta_description: learningPath.seo_meta_description,

    published_at:
      learningPath.published_at && moment(learningPath.published_at).toDate(),
    archived_at:
      learningPath.archived_at && moment(learningPath.archived_at).toDate(),

    next_learning_path: learningPath.next_learning_path
      ? learningPath.next_learning_path
      : -1
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={GeneralDataSchema}
      onSubmit={async values => {
        const data = {
          ...values,
          next_learning_path:
            values.next_learning_path > -1 ? values.next_learning_path : null
        };

        await toast.promise(
          editLearningPathGeneralData.mutateAsync(data),
          {
            loading: 'Cargando...',
            success: 'Datos Generales editado',
            error: 'Error'
          },
          { success: { icon: <UpdateIcon color="primary" /> } }
        );

        remove();
        refetch();
      }}
    >
      {({
 errors, touched, values, handleChange, setFieldValue 
}) => (
        <Form>
          <Grid container rowSpacing={2} alignItems="flex-start">
            {/* Main */}
            <Grid item container columnSpacing={2} rowSpacing={2} xs={12}>
              {/* Name */}
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  fullWidth
                  id="name"
                  label="Nombre"
                  placeholder="Nombre"
                  variant="outlined"
                  value={values.name}
                  onChange={handleChange}
                />
                {errors.name && touched.name && (
                  <Alert severity="error">{getErrorMessage(errors.name)}</Alert>
                )}
              </Grid>

              {/* Slug */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="slug"
                  label="Slug"
                  placeholder="Slug"
                  variant="outlined"
                  value={values.slug}
                  onChange={handleChange}
                />
                {errors.slug && touched.slug && (
                  <Alert severity="error">{getErrorMessage(errors.slug)}</Alert>
                )}
              </Grid>

              {/* Youtube_video_id */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="youtube_video_id"
                  label="Youtube Video Id"
                  placeholder="Youtube Video Id"
                  variant="outlined"
                  value={values.youtube_video_id}
                  onChange={handleChange}
                />
                {errors.youtube_video_id && touched.youtube_video_id && (
                  <Alert severity="error">
                    {getErrorMessage(errors.youtube_video_id)}
                  </Alert>
                )}
              </Grid>

              {/* Call_out */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="call_out"
                  label="Call Out"
                  placeholder="Call Out"
                  variant="outlined"
                  value={values.call_out}
                  onChange={handleChange}
                />
                {errors.call_out && touched.call_out && (
                  <Alert severity="error">
                    {getErrorMessage(errors.call_out)}
                  </Alert>
                )}
              </Grid>

              {/* Title */}
              <Grid item xs={12}>
                <TextareaAutosize
                  id="title"
                  aria-label="minimum height"
                  placeholder="Descripción - Title"
                  minRows={5}
                  value={values.title}
                  onChange={handleChange}
                  style={{ width: '99%', minWidth: '99%', maxWidth: '99%' }}
                />
                {errors.title && touched.title && (
                  <Alert severity="error">
                    {getErrorMessage(errors.title)}
                  </Alert>
                )}
              </Grid>

              {/* Description */}
              <Grid item xs={12}>
                <TextareaAutosize
                  id="description"
                  aria-label="minimum height"
                  placeholder="Descripción - Párrafo"
                  minRows={10}
                  value={values.description}
                  onChange={handleChange}
                  style={{ width: '99%', minWidth: '99%', maxWidth: '99%' }}
                />
                {errors.description && touched.description && (
                  <Alert severity="error">
                    {getErrorMessage(errors.description)}
                  </Alert>
                )}
              </Grid>

              {/* Seo_title */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="seo_title"
                  label="Seo Title"
                  placeholder="Seo Title"
                  variant="outlined"
                  value={values.seo_title}
                  onChange={handleChange}
                />
                {errors.seo_title && touched.seo_title && (
                  <Alert severity="error">
                    {getErrorMessage(errors.seo_title)}
                  </Alert>
                )}
              </Grid>

              {/* Seo_meta_description */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="seo_meta_description"
                  label="Seo Meta Description"
                  placeholder="Seo Meta Description"
                  variant="outlined"
                  value={values.seo_meta_description}
                  onChange={handleChange}
                />
                {errors.seo_meta_description &&
                  touched.seo_meta_description && (
                    <Alert severity="error">
                      {getErrorMessage(errors.seo_meta_description)}
                    </Alert>
                  )}
              </Grid>

              {/* published_at | archived_at */}
              <Grid item container columnSpacing={2} rowSpacing={1} xs={12}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  {/* published_at */}
                  <Grid item xs={6}>
                    <DateTimePicker
                      label="Publicado"
                      inputFormat="yyyy-MM-dd"
                      value={values.published_at}
                      disableMaskedInput
                      ampm={false}
                      onChange={(value: any) =>
                        setFieldValue('published_at', value)
                      }
                      renderInput={(params: any) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                    {errors.published_at && touched.published_at && (
                      <Alert severity="error">
                        {getErrorMessage(errors.published_at)}
                      </Alert>
                    )}
                  </Grid>

                  {/* archived_at */}
                  <Grid item xs={6}>
                    <DateTimePicker
                      label="Archivado"
                      inputFormat="yyyy-MM-dd"
                      value={values.archived_at}
                      disableMaskedInput
                      minDate={values.published_at}
                      ampm={false}
                      onChange={(value: any) =>
                        setFieldValue('archived_at', value)
                      }
                      renderInput={(params: any) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                    {errors.archived_at && touched.archived_at && (
                      <Alert severity="error">
                        {getErrorMessage(errors.archived_at)}
                      </Alert>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    {values.published_at ? (
                      <Alert variant="standard" severity="success">
                        El grupo esta publicado
                      </Alert>
                    ) : (
                      <Alert variant="standard" severity="warning">
                        El grupo esta privado
                      </Alert>
                    )}
                  </Grid>
                </LocalizationProvider>
              </Grid>

              {/* Next_learning_path */}
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  id="next_learning_path"
                  options={sortAlphabetically(learningPaths!.all, 'name')}
                  autoHighlight
                  value={
                    learningPaths!.all.find(
                      learningPath =>
                        learningPath.id === values.next_learning_path
                    ) || null
                  }
                  onChange={(_e, value: any) => {
                    value
                      ? setFieldValue('next_learning_path', value.id)
                      : setFieldValue('next_learning_path', -1);
                  }}
                  getOptionLabel={option => `${option.name} (${option.id})`}
                  renderOption={(props, option) => (
                    <Box key={option.id} component="li" {...props}>
                      {option.name} ({option.id})
                    </Box>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="Next Learning Path"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {errors.next_learning_path && touched.next_learning_path && (
                  <Alert severity="error">
                    {getErrorMessage(errors.next_learning_path)}
                  </Alert>
                )}
              </Grid>
            </Grid>

            {/* Edit */}
            <Grid item container rowSpacing={2} xs={12}>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  loading={editLearningPathGeneralData.isLoading}
                  variant="contained"
                  type="submit"
                  startIcon={<UpdateIcon />}
                >
                  Editar
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default GeneralData;
