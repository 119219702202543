import {
  Dialog,
  Alert,
  Button,
  Typography,
  AlertTitle,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import moment from 'moment';
import { useState } from 'react';
import { DatePicker } from '@mui/lab';
import { toast } from 'react-hot-toast';
import { TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import './attendanceStudent.scss';
import { updateAttendanceDate } from '../../../../../services/rooms';
import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import { IRoomDetailSimplified } from '../../../../../interfaces/room.interface';
import useAttendanceByStudent from '../../../../../hooks/useAttendanceByStudent';
import TableAttendanceStudentV2 from '../../../../../components/organism/TableAttendanceStudent/TableAttendanceStudentV2';

interface Props {
  roomInfo: IRoomDetailSimplified;
  refetch: () => void;
}

export const AttendanceStudent: React.FC<Props> = ({ roomInfo, refetch }) => {
  const { roomId } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [valueDate, setValueDate] = useState('');
  const [valueDateId, setValueDateId] = useState<number | null>(null);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const refetchDetailRoom = () => {
    setShouldRefetch(!shouldRefetch);
  };

  const {
    data: studentRecords,
    isLoading,
    isError
  } = useAttendanceByStudent({
    roomId: Number(roomId),
    shouldRefetch: shouldRefetch
  });

  const handleUpdateAttendanceDate = async (
    sessionId: number,
    startDate: string
  ) => {
    const date = moment(startDate).format('YYYY-MM-DD');
    try {
      const response = await updateAttendanceDate(sessionId, date);
      if (response?.status === 200) {
        toast.success('La fecha se actualizó correctamente!');
        refetchDetailRoom();
      } else {
        toast.error('No se pudo actualizar');
      }
    } catch (error) {
      toast.error(
        'Ha ocurrido un error. Inicie la clase con la cuenta correcta'
      );
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  if (isLoading || !roomInfo) return <ScreenLoader />;

  if (isError) {
    return (
      <Alert
        severity="error"
        variant="outlined"
        action={
          <Button
            color="inherit"
            size="small"
            onClick={() => {
              refetchDetailRoom();
            }}
          >
            Reintentar
          </Button>
        }
      >
        <AlertTitle>Error</AlertTitle>
        No se pudo obtener la información de los estudiantes. Por favor
        reintente.
      </Alert>
    );
  }

  return (
    <>
      <TableAttendanceStudentV2
        roomInfo={roomInfo}
        studentRecords={studentRecords}
        refetch={refetch}
        refetchAttendanceDetails={refetchDetailRoom}
        setOpenModal={setOpenModal}
        setValueDateId={setValueDateId}
        setValueDate={setValueDate}
      />

      <div className="attendance-student-v2">
        <div className="attendance-student-v2__content">
          <Typography className="attendance-student-v2__content--text">
            La asistencia de los estudiantes se guarda de manera automática
          </Typography>
        </div>
        <div className="attendance-student-v2__status-tags">
          <Typography className="attendance-student-v2__status-tags-item attendance-student-v2__status-tags-item--attended">
            <strong>A</strong> = Asistencia
          </Typography>
          <Typography className="attendance-student-v2__status-tags-item attendance-student-v2__status-tags-item--delay">
            <strong>T</strong> = Tardanza
          </Typography>
          <Typography className="attendance-student-v2__status-tags-item attendance-student-v2__status-tags-item--absence">
            <strong>F</strong> = Falta
          </Typography>
          <Typography className="attendance-student-v2__status-tags-item attendance-student-v2__status-tags-item--justified">
            <strong>J</strong> = Justificación
          </Typography>
          <Typography className="attendance-student-v2__status-tags-item attendance-student-v2__status-tags-item--recovered">
            <strong>R</strong> = Recuperación
          </Typography>
          <Typography className="attendance-student-v2__status-tags-item attendance-student-v2__status-tags-item--material">
            <strong>MR</strong> = Material Revisado
          </Typography>
        </div>
      </div>

      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Editar Fecha</DialogTitle>
        <DialogContent>
          <br />
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DatePicker
              label="Fecha sesión"
              inputFormat="yyyy-MM-dd"
              value={moment(valueDate).add(1, 'days').format('YYYY-MM-DD')}
              disableMaskedInput
              onChange={(newValue: any) => {
                if (newValue) {
                  setValueDate(newValue);
                }
              }}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="contained" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              if (valueDate && valueDateId) {
                handleUpdateAttendanceDate(valueDateId, valueDate);
              }
              handleClose();
            }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
