// Material UI
import Box from '@mui/material/Box';

import './../../../rooms.scss';
import { AttendanceStudent } from './AttendanceStudent';
import { IRoomDetailSimplified } from '../../../../../interfaces/room.interface';

interface Props {
  refetch: () => void;
  room: IRoomDetailSimplified;
}

export const Students: React.FC<Props> = ({ refetch, room }) => {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          marginBottom: '1em',
          marginTop: '1em',
          paddingBottom: '1em',
          boxShadow: 1
        }}
      >
        <AttendanceStudent roomInfo={room} refetch={refetch} />
      </Box>
    </>
  );
};
