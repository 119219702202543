import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
 Button, Alert, AlertTitle, Typography 
} from '@material-ui/core';

import './../../../rooms.scss';
import './attendanceTeacher.scss';
import {
  ADMIN,
  TEACHER_AUXILIARY,
  TEACHER_CTC
} from '../../../../../constants/usersType.constants';
import { RootState } from '../../../../../store/store';
import { useModal } from '../../../../../hooks/useModal';
import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import IconAddSymbol from '../../../../../components/atoms/icons/IconAddSymbol';
import { IRoomDetailSimplified } from '../../../../../interfaces/room.interface';
import useAttendanceByTeacher from '../../../../../hooks/useAttendanceByTeacher';
import ButtonOutline from '../../../../../components/molecules/ButtonOutline/ButtonOutline';
import AuxTeacherActionsModal from '../../../../../components/organism/AuxTeacherActionsModal';
import ModalChangeTeacherV2 from '../../../../../pages/Rooms/ModalChangeTeacher/ModalChangeTeacherV2';
import TableAttendanceTeacher from '../../../../../components/organism/TableAttendanceTeacher/TableAttendanceTeacherV2';

interface Props {
  roomInfo: IRoomDetailSimplified;
  refetch: () => void;
  teacherType?: 'teacherCTC' | 'AuxiliaryTeacher';
}

export const AttendanceTeacher: React.FC<Props> = ({
  roomInfo,
  refetch,
  teacherType = 'teacherCTC'
}) => {
  const modalRef = useRef<any>(null);
  const user = useSelector((state: RootState) => state.auth.user);
  const allowedRoles = [TEACHER_CTC, ADMIN];
  const isAllowedUserTabAuxTeacher =
    user && allowedRoles.includes(user?.permission?.name) ? true : false;

  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [externalTeachers, setExternalTeachers] = useState<any>([]);

  const refetchDetailRoom = () => {
    setShouldRefetch(!shouldRefetch);
  };

  const {
    data: currentTeacherRecords,
    isLoading,
    isError
  } = useAttendanceByTeacher({
    roomId: Number(roomInfo.id),
    teacherType: teacherType === 'teacherCTC' ? 'teacher' : 'auxiliar_teacher',
    shouldRefetch: shouldRefetch
  });

  const {
    openModal: openModalChangeTeacher,
    closeModal: closeModalChangeTeacher,
    isOpenModal: isOpenModalChangeTeacher
  } = useModal();

  const handleClickAddExtTeacher = () => {
    if (modalRef.current) {
      modalRef.current.setInitialValuesExternalTeacher();
    }
  };

  if (isLoading || !roomInfo) return <ScreenLoader />;

  if (isError) {
    return (
      <Alert
        severity="error"
        variant="outlined"
        action={
          <Button
            color="inherit"
            size="small"
            onClick={() => {
              refetchDetailRoom();
            }}
          >
            Reintentar
          </Button>
        }
      >
        <AlertTitle>Error</AlertTitle>
        No se pudo obtener la información de los profesores. Por favor
        reintente.
      </Alert>
    );
  }

  return (
    <>
      <TableAttendanceTeacher
        teacherType={teacherType}
        roomInfo={roomInfo}
        currentTeacherRecords={currentTeacherRecords}
        refetch={refetch}
        refetchAttendanceDetails={refetchDetailRoom}
      />
      <AuxTeacherActionsModal
        ref={modalRef}
        roomInfo={roomInfo}
        refetchDetailRoom={refetch}
        externalTeachers={externalTeachers}
        setExternalTeachers={setExternalTeachers}
      />

      <div className="attendance-teacher-v2__attendance-teacher-tags">
        <div className="attendance-teacher-v2__attendance-teacher-tags__info">
          {user?.permission?.name !== TEACHER_AUXILIARY ? (
            <Typography className="attendance-teacher-v2__attendance-teacher-tags__info-text">
              La asistencia de los profesores se guarda de manera automática
            </Typography>
          ) : null}
        </div>
        <div className="attendance-teacher-v2__attendance-teacher-tags__status-tags">
          <Typography className="attendance-teacher-v2__attendance-teacher-tags__status-tags-item attendance-teacher-v2__attendance-teacher-tags__status-tags-item--attended">
            <strong>A</strong> = Asistencia
          </Typography>
          <Typography className="attendance-teacher-v2__attendance-teacher-tags__status-tags-item attendance-teacher-v2__attendance-teacher-tags__status-tags-item--delay">
            <strong>T</strong> = Tardanza
          </Typography>
          <Typography className="attendance-teacher-v2__attendance-teacher-tags__status-tags-item attendance-teacher-v2__attendance-teacher-tags__status-tags-item--absence">
            <strong>F</strong> = Falta
          </Typography>
          <Typography className="attendance-teacher-v2__attendance-teacher-tags__status-tags-item attendance-teacher-v2__attendance-teacher-tags__status-tags-item--justified">
            <strong>J</strong> = Justificación
          </Typography>
          <Typography className="attendance-teacher-v2__attendance-teacher-tags__status-tags-item attendance-teacher-v2__attendance-teacher-tags__status-tags-item--justified">
            <strong>N/A</strong> = No Aplica
          </Typography>
          {teacherType === 'AuxiliaryTeacher' ? (
            <Typography className="attendance-teacher-v2__attendance-teacher-tags__status-tags-item attendance-teacher-v2__attendance-teacher-tags__status-tags-item--substitution">
              <strong>S</strong> = Suplencia
            </Typography>
          ) : null}
        </div>
      </div>

      {user?.permission?.name === ADMIN && teacherType === 'teacherCTC' ? (
        <>
          <ButtonOutline
            size="small"
            onClick={() => openModalChangeTeacher()}
            iconRight={
              <IconAddSymbol
                fill={'#000000'}
                className="attendance-teacher-v2__attendance-outline-button"
              />
            }
            className="room-details-add-teacher-button"
          >
            Cambiar profesor
          </ButtonOutline>
          <ModalChangeTeacherV2
            roomInfo={roomInfo}
            isOpenModal={isOpenModalChangeTeacher}
            openModal={closeModalChangeTeacher}
            closeModal={closeModalChangeTeacher}
            refetchRoomDetails={refetch}
            refetchAttendanceDetails={refetchDetailRoom}
          />
        </>
      ) : isAllowedUserTabAuxTeacher &&
        teacherType === 'AuxiliaryTeacher' &&
        externalTeachers.length < 3 &&
        roomInfo.business_type === 'B2B' ? (
        <ButtonOutline
          size="small"
          onClick={handleClickAddExtTeacher}
          iconRight={
            <IconAddSymbol
              fill={'#000000'}
              className="attendance-teacher-v2__attendance-outline-button"
            />
          }
        >
          Agregar profesor externo ({3 - externalTeachers.length}) +
        </ButtonOutline>
      ) : null}
    </>
  );
};
