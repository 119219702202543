import * as Yup from 'yup';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import DateAdapter from '@mui/lab/AdapterDateFns';
import React, { useState, useEffect } from 'react';
import { Alert, LinearProgress, TextField } from '@mui/material';
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  TimePicker
} from '@mui/lab';

import './modalCreateGroupV2.scss';
import {
  IGroupDetail,
  IGroupDetailInitialData
} from '../../../interfaces/group.interface';
import {
  addGroup,
  getAllGroups,
  selectStateGroups
} from '../../../slices/groupSlice/groupsSlice';
import {
  getAllCourses,
  selectStateCourses
} from '../../../slices/courseSlice/coursesSlice';
import { useModal } from '../../../hooks/useModal';
import Modal from '../../../components/atoms/Modal';
import colors from '../../../components/atoms/Colors';
import Input from '../../../components/molecules/Input';
import { getErrorMessage, sortAlphabetically } from '../../../utils/utils';
import IconLock from '../../../components/atoms/icons/IconLock';
import IconAlert from '../../../components/atoms/icons/IconAlert';
import IconCheck from '../../../components/atoms/icons/IconCheck';
import IconClock from '../../../components/atoms/icons/IconClock';
import { AppDispatch, useAppDispatch } from '../../../store/store';
import ButtonFilled from '../../../components/molecules/ButtonFilled';
import ButtonOutline from '../../../components/molecules/ButtonOutline';
import IconCalendar from '../../../components/atoms/icons/IconCalendar';
import Typography from '../../../components/atoms/Typography/Typography';
import SelectComp from '../../../components/molecules/SelectComp/SelectComp';
import ModalMessageAlert from '../../../components/molecules/ModalMessageAlert';
import SelectFilter from '../../../components/molecules/SelectFilter/SelectFilter';

interface Props {
  isOpenModal: boolean;
  openModal: () => void;
  closeModal: () => void;
  refetch?: () => {};
  openModalConfirmCreateGroup: () => void;
  setDataGroupCreated: any;
}

const ModalCreateGroupV2: React.FC<Props> = ({
  isOpenModal,
  openModal,
  closeModal,
  refetch,
  openModalConfirmCreateGroup,
  setDataGroupCreated
}) => {
  const dispatch: AppDispatch = useAppDispatch();
  const { courses, isLoading: isLoadingCourses } =
    useSelector(selectStateCourses);
  const { isCompleted, error: errorCreateGroup } =
    useSelector(selectStateGroups);
  const {
    openModal: openModalAlertCreateGroup,
    closeModal: closeModalAlertCreateGroup,
    isOpenModal: isOpenModalAlertCreateGroup
  } = useModal();
  const [alertCourse, setAlertCourse] = useState(false);
  const [isLoadingCreateGroup, setIsLoadingCreateGroup] = useState(false);
  const [courseToFilter, setCourseToFilter] = useState('');
  const [selectedFrecuency, setSelectedFrecuency] = useState<any>();
  const [selectedFrecuencyDays, setSelectedFrecuencyDays] = useState<any>();
  const [inputTime, setInputTime] = useState<any>();

  const [selectedDayFilter, setSelectedDayFilter] = useState<any>([]);
  const [duplicateDays, setDuplicateDays] = useState(false);
  const [isFrequencyCompleted, setIsFrequencyCompleted] = useState<boolean>();
  const [selectedDay, setSelectedDay] = useState<any>(['', '', '', '', '', '']);
  const [selectedHour, setSelectedHour] = useState<any>([
    '',
    '',
    '',
    '',
    '',
    ''
  ]);

  const [objDays, setObjDays] = useState([
    { id: 1, name: 'Lunes', isDisabled: false },
    { id: 2, name: 'Martes', isDisabled: false },
    { id: 3, name: 'Miércoles', isDisabled: false },
    { id: 4, name: 'Jueves', isDisabled: false },
    { id: 5, name: 'Viernes', isDisabled: false },
    { id: 6, name: 'Sábado', isDisabled: false }
  ]);

  const objFrecuency = [
    { id: 1, name: '1 día a la semana' },
    { id: 2, name: '2 días a la semana' },
    { id: 3, name: '3 días a la semana' },
    { id: 4, name: '4 días a la semana' },
    { id: 5, name: '5 días a la semana' },
    { id: 6, name: '6 días a la semana' }
  ];

  useEffect(() => {
    dispatch(getAllCourses());
  }, []);

  useEffect(() => {
    cleanSelectsDays();
  }, [selectedFrecuencyDays, setSelectedFrecuencyDays]);

  useEffect(() => {
    const selectedFrequencyHoursGroup = selectedHour.filter(
      (hour: any) => hour !== ''
    ).length;
    if (selectedFrequencyHoursGroup !== selectedFrecuencyDays) {
      setIsFrequencyCompleted(false);
    } else {
      setIsFrequencyCompleted(true);
    }
  }, [selectedHour, selectedFrecuencyDays]);

  const IconClockDisabled = () => <IconClock fill="#e5e5e5" />;
  const IconClockEnabled = () => <IconClock />;

  const createGroup = async (newGroup: IGroupDetail) => {
    try {
      const res = await dispatch(addGroup(newGroup));
      setDataGroupCreated({
        ...res?.payload?.data,
        course: { id: res?.payload?.data.course, name: courseToFilter }
      });
      openModalConfirmCreateGroup();
    } catch (error) {
      console.log(error);
      toast.error('Ocurrió un error al crear el grupo');
    } finally {
      await dispatch(getAllGroups({ currentPage: 1, size: 100 }));
    }
  };

  const handleClickCourse = (option: any): void => {
    setCourseToFilter(option.name);
  };

  const handleClickFrecuency = (option: any): void => {
    setSelectedFrecuency(option);
    setSelectedFrecuencyDays(option.id);
  };

  const handleClickTime = (option: any): void => {
    setInputTime(option.target.value);
  };

  const cleanSelects = () => {
    setSelectedFrecuency(null);
    setInputTime('');
    setCourseToFilter('');
    cleanSelectsDays();
  };

  const cleanSelectsDays = () => {
    setSelectedDay(['', '', '', '', '', '']);
    setSelectedHour(['', '', '', '', '', '']);
    setSelectedDayFilter([]);
    setDuplicateDays(false);

    setObjDays(currentObjDays =>
      currentObjDays.map((obj, idx) =>
        obj.isDisabled === true ? { ...obj, isDisabled: false } : obj
      )
    );
  };

  const handleClickDay = (option: any, index: any): void => {
    if (selectedDay[index].isDisabled === option.isDisabled) {
      objDays[selectedDay[index].id - 1].isDisabled = false;
    }
    selectedDay[index] = option;
    selectedDayFilter[index] = option.id;

    setObjDays(currentObjDays =>
      currentObjDays.map((obj, idx) =>
        obj?.id === option?.id ? { ...obj, isDisabled: true } : obj
      )
    );
  };

  const initialValues: IGroupDetailInitialData = {
    name: '',
    course: -1,

    start_date: null,
    end_date: null,

    published_at: null,
    archived_at: null,

    time_monday: null,
    time_tuesday: null,
    time_wednesday: null,
    time_thursday: null,
    time_friday: null,
    time_saturday: null,

    duration_monday: 0,
    duration_tuesday: 0,
    duration_wednesday: 0,
    duration_thursday: 0,
    duration_friday: 0,
    duration_saturday: 0
  };

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .min(4, 'Muy pequeño!')
      .max(500, 'Mucho texto!')
      .required('Requerido'),
    course: Yup.number()
      .test('Is positive?', 'Seleccione un curso', value => value! > 0)
      .required('Requerido'),

    start_date: Yup.date().nullable(true).required('Requerido'),
    end_date: Yup.date()
      .nullable(true)
      .min(
        Yup.ref('start_date'),
        'FIN del curso NO puede ser antes que el INICIO del curso'
      )
      .required('Requerido'),

    published_at: Yup.date().nullable(true),
    archived_at: Yup.date()
      .nullable(true)
      .min(
        Yup.ref('published_at'),
        'ARCHIVADO NO puede ser antes que PUBLICADO'
      ),

    time_monday: Yup.date().nullable(true),
    time_tuesday: Yup.date().nullable(true),
    time_wednesday: Yup.date().nullable(true),
    time_thursday: Yup.date().nullable(true),
    time_friday: Yup.date().nullable(true),
    time_saturday: Yup.date().nullable(true),

    duration_monday: Yup.number().test(
      'Is positive?',
      'Solo números positivos',
      value => value! >= 0
    ),
    duration_tuesday: Yup.number().test(
      'Is positive?',
      'Solo números positivos',
      value => value! >= 0
    ),
    duration_wednesday: Yup.number().test(
      'Is positive?',
      'Solo números positivos',
      value => value! >= 0
    ),
    duration_thursday: Yup.number().test(
      'Is positive?',
      'Solo números positivos',
      value => value! >= 0
    ),
    duration_friday: Yup.number().test(
      'Is positive?',
      'Solo números positivos',
      value => value! >= 0
    ),
    duration_saturday: Yup.number().test(
      'Is positive?',
      'Solo números positivos',
      value => value! >= 0
    )
  });

  const handleChangeHours = (
    newValue: any,
    index: any,
    setFieldValue: any
  ): void => {
    setSelectedHour((prevValues: any) => {
      const newSelectedHours = [...prevValues];
      newSelectedHours[index] = newValue;
      return newSelectedHours;
    });

    if (selectedDay[index] && selectedDay[index].id === 1) {
      setFieldValue('time_monday', newValue);
    }
    if (selectedDay[index] && selectedDay[index].id === 2) {
      setFieldValue('time_tuesday', newValue);
    }
    if (selectedDay[index] && selectedDay[index].id === 3) {
      setFieldValue('time_wednesday', newValue);
    }
    if (selectedDay[index] && selectedDay[index].id === 4) {
      setFieldValue('time_thursday', newValue);
    }
    if (selectedDay[index] && selectedDay[index].id === 5) {
      setFieldValue('time_friday', newValue);
    }
    if (selectedDay[index] && selectedDay[index].id === 6) {
      setFieldValue('time_saturday', newValue);
    }
  };

  if (isLoadingCourses || !courses) return <LinearProgress />;

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={SignupSchema}
        onSubmit={async (values, { resetForm }) => {
          const duplicados = selectedDayFilter.some(
            (value: any) =>
              selectedDayFilter.indexOf(value) !==
              selectedDayFilter.lastIndexOf(value)
          );
          setDuplicateDays(duplicados);
          const data = {
            name: values.name,
            course: values.course,

            start_date:
              values.start_date &&
              moment(values.start_date).format('YYYY-MM-DD'),
            end_date:
              values.end_date && moment(values.end_date).format('YYYY-MM-DD'),

            published_at: values.published_at && values.published_at,
            archived_at: values.archived_at && values.archived_at,

            time_monday:
              values.time_monday &&
              moment(values.time_monday).format('H:mm:ss'),
            time_tuesday:
              values.time_tuesday &&
              moment(values.time_tuesday).format('H:mm:ss'),
            time_wednesday:
              values.time_wednesday &&
              moment(values.time_wednesday).format('H:mm:ss'),
            time_thursday:
              values.time_thursday &&
              moment(values.time_thursday).format('H:mm:ss'),
            time_friday:
              values.time_friday &&
              moment(values.time_friday).format('H:mm:ss'),
            time_saturday:
              values.time_saturday &&
              moment(values.time_saturday).format('H:mm:ss'),

            duration_monday: values.time_monday ? inputTime : 0,
            duration_tuesday: values.time_tuesday ? inputTime : 0,
            duration_wednesday: values.time_wednesday ? inputTime : 0,
            duration_thursday: values.time_thursday ? inputTime : 0,
            duration_friday: values.time_friday ? inputTime : 0,
            duration_saturday: values.time_saturday ? inputTime : 0
          };

          if (!duplicados) {
            await createGroup(data!);
            closeModal();
            resetForm();
            cleanSelects();
          }
        }}
      >
        {({
 errors, touched, values, handleChange, setFieldValue 
}) => {
          return (
            <Form>
              <Modal
                width="xl"
                isOpen={isOpenModal}
                closeModal={closeModal}
                title={'Crear Grupo'}
                subtitle={
                  <Typography type="paragraph2" variation="mobile">
                    Algunos datos del grupo (*) solo pueden ser modificados
                    antes de que comience el curso. Al modificar los datos, se
                    actualizan automáticamente sin tener que hacerlo
                    manualmente.{' '}
                  </Typography>
                }
                body={
                  <div className="modal-create-group-v2__content-body">
                    <div className="modal-create-group-v2__group-information">
                      <Typography bold type="paragraph2" variation="mobile">
                        Nombre del grupo
                      </Typography>
                      <Input
                        placeholder="Dale un nombre al grupo nuevo"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        required
                        error={errors.name && touched.name ? errors.name : ''}
                      />
                      <Typography bold type="paragraph2" variation="mobile">
                        Curso*
                      </Typography>
                      <SelectFilter
                        sort
                        size="default"
                        placeholder="Elegir curso"
                        onclickDefaultValue={(event: any) => {
                          handleClickCourse(event);
                          setFieldValue('course', event?.id);
                        }}
                        identifier="name"
                        options={sortAlphabetically(courses, 'name')}
                        disabled={!sortAlphabetically(courses, 'name')}
                        valueToFilter={courseToFilter}
                        setValueToFilter={setCourseToFilter}
                        alert={alertCourse}
                      />
                      <Typography bold type="paragraph2" variation="mobile">
                        Inicio del curso*
                      </Typography>
                      <div className="modal-create-group-v2__group-start-day">
                        <LocalizationProvider dateAdapter={DateAdapter}>
                          <div className="modal-create-group-v2__group-error">
                            <DatePicker
                              inputFormat="yyyy-MM-dd"
                              value={values.start_date}
                              disableMaskedInput
                              onChange={(value: any) =>
                                setFieldValue('start_date', value)
                              }
                              renderInput={(params: any) => (
                                <TextField
                                  fullWidth
                                  sx={{
                                    input: {
                                      padding: 0,
                                      paddingLeft: '15px',
                                      height: '40px'
                                    },
                                    '.MuiOutlinedInput-root': {borderRadius: '8px'},
                                    fieldset: { border: '1px solid black' }
                                  }}
                                  {...params}
                                />
                              )}
                              components={{ OpenPickerIcon: IconCalendar }}
                            />
                            {errors.start_date && touched.start_date && (
                              <Typography
                                type="paragraph2"
                                variation="mobile"
                                className="modal-create-group-v2__alert"
                                color={colors.warningColor}
                              >
                                {errors.start_date}
                              </Typography>
                            )}
                          </div>
                          <div className="modal-create-group-v2__group-end-day">
                            <Typography
                              bold
                              type="paragraph2"
                              variation="mobile"
                            >
                              Fin del curso*
                            </Typography>
                            <div className="modal-create-group-v2__group-error">
                              <DatePicker
                                inputFormat="yyyy-MM-dd"
                                value={values.end_date}
                                disableMaskedInput
                                minDate={values.start_date}
                                onChange={(value: any) =>
                                  setFieldValue('end_date', value)
                                }
                                renderInput={(params: any) => (
                                  <TextField
                                    fullWidth
                                    sx={{
                                      input: {
                                        padding: 0,
                                        paddingLeft: '15px',
                                        height: '40px'
                                      },
                                      '.MuiOutlinedInput-root': {borderRadius: '8px'},
                                      fieldset: { border: '1px solid black' }
                                    }}
                                    {...params}
                                  />
                                )}
                                components={{ OpenPickerIcon: IconCalendar }}
                              />
                              {errors.end_date && touched.end_date && (
                                <Typography
                                  type="paragraph2"
                                  variation="mobile"
                                  className="modal-create-group-v2__alert"
                                  color={colors.warningColor}
                                >
                                  {errors.end_date}
                                </Typography>
                              )}
                            </div>
                          </div>
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div>
                      <Typography
                        className="modal-create-group-v2__date-advice"
                        type="paragraph2"
                        variation="mobile"
                        color="#494D4B"
                      >
                        (Asegúrate que las fechas de inicio y fin estén
                        comprendidas dentro de los días de la semana que
                        selecciones para este grupo.)
                      </Typography>
                    </div>
                    <div className="modal-create-group-v2__group-information">
                      <Typography bold type="paragraph2" variation="mobile">
                        Publicado
                      </Typography>
                      <div className="modal-create-group-v2__group-start-day">
                        <LocalizationProvider dateAdapter={DateAdapter}>
                          <DateTimePicker
                            inputFormat="yyyy-MM-dd"
                            value={values.published_at}
                            disableMaskedInput
                            ampm={false}
                            onChange={(value: any) =>
                              setFieldValue('published_at', value)
                            }
                            renderInput={(params: any) => (
                              <TextField
                                fullWidth
                                sx={{
                                  input: {
                                    padding: 0,
                                    paddingLeft: '15px',
                                    height: '40px'
                                  },
                                  '.MuiOutlinedInput-root': {borderRadius: '8px'},
                                  fieldset: { border: '1px solid black' }
                                }}
                                {...params}
                              />
                            )}
                            components={{ OpenPickerIcon: IconCalendar }}
                          />
                          {errors.published_at && touched.published_at && (
                            <Alert severity="error">
                              {getErrorMessage(errors.published_at)}
                            </Alert>
                          )}
                          <div className="modal-create-group-v2__group-end-day">
                            <Typography
                              bold
                              type="paragraph2"
                              variation="mobile"
                            >
                              Archivado
                            </Typography>
                            <DateTimePicker
                              inputFormat="yyyy-MM-dd"
                              value={values.archived_at}
                              disableMaskedInput
                              minDate={values.published_at}
                              ampm={false}
                              onChange={(value: any) =>
                                setFieldValue('archived_at', value)
                              }
                              renderInput={(params: any) => (
                                <TextField
                                  fullWidth
                                  sx={{
                                    input: {
                                      padding: 0,
                                      paddingLeft: '15px',
                                      height: '40px'
                                    },
                                    '.MuiOutlinedInput-root': {borderRadius: '8px'},
                                    fieldset: { border: '1px solid black' }
                                  }}
                                  {...params}
                                />
                              )}
                              components={{ OpenPickerIcon: IconCalendar }}
                            />
                            {errors.archived_at && touched.archived_at && (
                              <Alert severity="error">
                                {getErrorMessage(errors.archived_at)}
                              </Alert>
                            )}
                          </div>
                        </LocalizationProvider>
                      </div>
                      <Typography bold type="paragraph2" variation="mobile">
                        Duración*
                      </Typography>
                      <div className="modal-create-group-v2__group-duration">
                        <Input
                          placeholder="000"
                          name="duration"
                          value={inputTime}
                          onChange={(e: any) => {
                            handleChange(e);
                            handleClickTime(e);
                          }}
                        />
                        <Typography bold type="paragraph2" variation="mobile">
                          minutos
                        </Typography>
                      </div>
                      <Typography bold type="paragraph2" variation="mobile">
                        Número de días*
                      </Typography>
                      <SelectComp
                        size="small"
                        onclickDefaultValue={handleClickFrecuency}
                        options={objFrecuency}
                        dividerItems
                        value={selectedFrecuency}
                        placeholder="¿Cuántos días a la semana será la clase?"
                      />
                      <Typography bold type="paragraph2" variation="mobile">
                        Elegir día*
                      </Typography>
                      <div className="modal-create-group-v2__group-frecuency">
                        {[...Array(Number(selectedFrecuencyDays) || 1)].map(
                          (_, index) => (
                            <>
                              <SelectComp
                                className="modal-create-group-v2__group-day"
                                disabled={!selectedFrecuency ? true : false}
                                size="small"
                                onclickDefaultValue={(event: any) => {
                                  handleClickDay(event, index);
                                }}
                                options={objDays}
                                dividerItems
                                value={selectedDay[index]}
                                placeholder="-"
                              />
                              {index === 0 ? (
                                <Typography
                                  bold
                                  type="paragraph2"
                                  variation="mobile"
                                >
                                  Hora*
                                </Typography>
                              ) : (
                                <p> </p>
                              )}
                              <div className="modal-create-group-v2__group-hour">
                                <LocalizationProvider dateAdapter={DateAdapter}>
                                  <TimePicker
                                    disabled={!selectedFrecuency ? true : false}
                                    value={
                                      selectedDay[index] &&
                                      selectedDay[index].id
                                        ? selectedHour[index]
                                        : null
                                    }
                                    ampm={false}
                                    onChange={(newValue: any) => {
                                      handleChangeHours(
                                        newValue,
                                        index,
                                        setFieldValue
                                      );
                                    }}
                                    renderInput={(params: any) => (
                                      <TextField
                                        focused={
                                          selectedDay[index]?.id &&
                                          !selectedHour[index]
                                            ? true
                                            : false
                                        }
                                        sx={{
                                          input: {
                                            padding: 0,
                                            paddingLeft: '15px',
                                            height: '40px'
                                          },
                                          '.MuiOutlinedInput-root': {borderRadius: '8px'},
                                          fieldset: {border: '1px solid black'}
                                        }}
                                        {...params}
                                      />
                                    )}
                                    components={{
                                      OpenPickerIcon: !selectedFrecuency
                                        ? IconClockDisabled
                                        : IconClockEnabled
                                    }}
                                  />
                                  {errors.time_monday &&
                                    touched.time_monday && (
                                      <Alert severity="error">
                                        {getErrorMessage(errors.time_monday)}
                                      </Alert>
                                    )}
                                </LocalizationProvider>
                              </div>
                            </>
                          )
                        )}
                      </div>
                    </div>

                    <div
                      className="modal-edit-group__group-private"
                      style={{background: values.published_at ? '#E5FFEA' : '#E5E5E5'}}
                    >
                      {values.published_at ? (
                        <IconCheck fill="#206614" />
                      ) : (
                        <IconLock fill="#555555" />
                      )}
                      <Typography
                        color={values.published_at ? '#206614' : '#555555'}
                        type="paragraph2"
                        variation="mobile"
                      >
                        {values.published_at
                          ? 'El grupo está publicado'
                          : 'El grupo es privado'}
                      </Typography>
                    </div>

                    {duplicateDays && (
                      <div className="modal-create-group-v2__group-alert-days">
                        <IconAlert />
                        <Typography
                          color={colors.colorAlertRed}
                          type="paragraph2"
                          variation="mobile"
                        >
                          {
                            'De los dias que elegiste, tienes algunos repetidos.'
                          }
                        </Typography>
                      </div>
                    )}

                    <section className="modal-create-group-v2__container-buttons">
                      <ButtonOutline
                        type="reset"
                        size="default"
                        disabled={isLoadingCreateGroup}
                        onClick={() => {
                          setInputTime('');
                          setSelectedFrecuencyDays(null);
                          setSelectedFrecuency(null);
                          closeModal();
                        }}
                      >
                        Cancelar
                      </ButtonOutline>
                      <ButtonFilled
                        type="submit"
                        size="default"
                        disabled={
                          isLoadingCreateGroup ||
                          !selectedFrecuency ||
                          !isFrequencyCompleted
                        }
                        onClick={
                          courseToFilter === ''
                            ? () => setAlertCourse(true)
                            : () => setAlertCourse(false)
                        }
                      >
                        Finalizar
                      </ButtonFilled>
                    </section>
                  </div>
                }
              ></Modal>
            </Form>
          );
        }}
      </Formik>
      {errorCreateGroup && (
        <ModalMessageAlert
          width="xs"
          title={
            <Typography bold type="paragraph1" variation="desktop">
              Algo salió mal...
            </Typography>
          }
          message="Parece que has añadido un valor incorrecto o que ya expiró. Revisa y corrije el espacio marcado en rojo para continuar."
          isOpenModal={isOpenModalAlertCreateGroup}
          openModal={openModalAlertCreateGroup}
          closeModal={closeModalAlertCreateGroup}
          button1={
            <ButtonFilled size="default" onClick={closeModalAlertCreateGroup}>
              Aceptar
            </ButtonFilled>
          }
        />
      )}
    </div>
  );
};

export default ModalCreateGroupV2;
