import {
  Paper,
  Select,
  Table,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  FormControl,
  TableContainer,
  SelectChangeEvent,
  IconButton
} from '@material-ui/core';
import { useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import VisibilityIcon from '@mui/icons-material/Visibility';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  AttendanceType,
  patchAttendanceMutation,
  postCreateUniqueAttendance
} from '../../../services/attendance';
import './tableAttendanceTeacher.scss';
import colors from '../../atoms/Colors';
import {
  ITeacher,
  IDateSession,
  ITeacherRecord,
  IAuxiliaryTeacher,
  IRoomDetailSimplified
} from '../../../interfaces/room.interface';
import { RootState } from '../../../store/store';
import AuxTeacherActionsModal from '../AuxTeacherActionsModal';
import AttendanceIndicator from '../../common/AttendanceIndicator/AttendanceIndicator';
import {
  TEACHER_AUXILIARY,
  TEACHER_CTC
} from '../../../constants/usersType.constants';

interface Props {
  roomInfo: IRoomDetailSimplified;
  currentTeacherRecords: ITeacherRecord[] | undefined;
  refetch: () => void;
  refetchAttendanceDetails: () => void;
  teacherType?: 'teacherCTC' | 'AuxiliaryTeacher';
}

const TableAttendanceTeacherV2: React.FC<Props> = ({
  refetch,
  roomInfo,
  teacherType,
  currentTeacherRecords,
  refetchAttendanceDetails
}) => {
  const modalRef = useRef<any>(null);
  const user = useSelector((state: RootState) => state.auth.user);

  const [externalTeachers, setExternalTeachers] = useState<any>([]);

  const createUniqueAttendance = async (attendance: any) => {
    try {
      const data = await postCreateUniqueAttendance(attendance);
      if (data) {
        toast.success('Asistencia tomada correctamente!');
      } else {
        toast.error('Ha ocurrido un error al tomar la asistencia.');
      }
    } catch (error) {
      toast.error('Ha ocurrido un error al tomar la asistencia.');
    }
  };

  const updateAttendanceMutation = async (attendance: any) => {
    try {
      const data = await patchAttendanceMutation(attendance);
      if (data) {
        toast.success('Asistencia actualizada correctamente!');
      } else {
        toast.error('Ha ocurrido un error al actualizar la asistencia.');
      }
    } catch (error) {
      toast.error('Ha ocurrido un error al actualizar la asistencia.');
    }
  };

  const handleSubmitUniqueAttendance = async (
    event: any,
    numberOfSession: any,
    roomId: any,
    teacher: any,
    teacherType: 'teacherCTC' | 'AuxiliaryTeacher'
  ) => {
    const newAttendance = {
      number_of_session: numberOfSession,
      status: event.target.value,
      object_id: teacher,
      object_type:
        teacherType === 'AuxiliaryTeacher'
          ? AttendanceType.teacherAuxiliary
          : AttendanceType.teacher,
      room: roomId
    };

    await createUniqueAttendance({ ...newAttendance });
    refetchAttendanceDetails();
  };

  const handleUpdateStateAttendance = async (event: any, attendance: any) => {
    await updateAttendanceMutation({
      id: attendance.id,
      status: event.target.value
    });
    refetchAttendanceDetails();
  };

  const renderMenuItem = ({
    value,
    message,
    status,
    minWidth
  }: {
    value: number;
    message: string;
    status:
      | 'undefined'
      | 'attended'
      | 'delay'
      | 'absence'
      | 'justified'
      | 'recovered'
      | 'material'
      | 'N/A'
      | 'substitution';
    minWidth?: number | undefined;
  }) => {
    return (
      <MenuItem
        value={value}
        sx={{
          minWidth: minWidth,
          paddingTop: '6px',
          paddingBottom: '6px',
          paddingLeft: '8px'
        }}
      >
        <AttendanceIndicator message={message} status={status} />
      </MenuItem>
    );
  };

  const getAttendanceClass = ({
    session,
    teacher,
    teacherType,
    attendanceForCurrentSession
  }: {
    session: any;
    teacher: any;
    attendanceForCurrentSession: any;
    teacherType: 'teacherCTC' | 'AuxiliaryTeacher';
  }) => {
    const attendanceClasses: any = {
      0: 'table-attendance-teacher__enable',
      1: 'table-attendance-teacher__attended',
      2: 'table-attendance-teacher__delay',
      3: 'table-attendance-teacher__absence',
      4: 'table-attendance-teacher__justified',
      7: 'table-attendance-teacher__justified',
      8: 'table-attendance-teacher__substitution',
      default: 'table-attendance-teacher__disable'
    };

    if (
      (teacherType === 'teacherCTC' && session.teacher_id !== teacher.id) ||
      !session.state
    ) {
      return attendanceClasses.default;
    }

    return (
      attendanceClasses[attendanceForCurrentSession?.status] ||
      attendanceClasses.default
    );
  };

  const iconFillColors: any = {
    0: colors.black,
    1: colors.statusAttendedText,
    2: colors.statusDelayText,
    3: colors.statusAbsenceText,
    4: colors.statusJustifiedText,
    7: colors.statusJustifiedText,
    8: colors.statusSubstitutionText,
    default: colors.white
  };

  const RenderTableRowTeacher = ({
    teacher,
    teacherType
  }: {
    teacher: any;
    teacherType: 'teacherCTC' | 'AuxiliaryTeacher';
  }) => {
    const email = teacher?.email;
    const handleClickGetExtTeacher = () => {
      if (modalRef.current) {
        modalRef.current.getExternalTeacher({ email });
      }
    };

    return (
      <TableRow key={'teacher-attendance-row-' + teacher.id}>
        {/* Teacher */}
        <TableCell
          component="th"
          scope="row"
          key={'teacher-attendance-' + teacher.id}
          sx={{
            fontFamily: 'Sofia Pro',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 14,
            borderBottom: 'none',
            textAlign: 'center',
            p: '0px 0px 0px 0px'
          }}
          className="room-details-teacher-attendance-tables-sticky"
        >
          {teacherType === 'teacherCTC'
            ? teacher?.full_name
            : `${teacher?.name} ${teacher?.last_name}`}
          &nbsp;
          {teacher.id != roomInfo.main_teacher.id ? null : (
            <span className="room-details-teacher-attendance-role-indicator">
              T
            </span>
          )}
        </TableCell>
        {teacherType === 'AuxiliaryTeacher' &&
        user?.permission?.name !== TEACHER_AUXILIARY ? (
          <TableCell
            component="th"
            scope="row"
            sx={{
              left: 432,
              fontFamily: 'Sofia Pro',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 14,
              borderBottom: 'none',
              textAlign: 'center',
              p: '0px 0px 0px 0px'
            }}
            className="room-details-teacher-attendance-tables-sticky"
          >
            <IconButton
              style={{ padding: 0 }}
              onClick={handleClickGetExtTeacher}
            >
              <VisibilityIcon />
            </IconButton>
            <AuxTeacherActionsModal
              ref={modalRef}
              roomInfo={roomInfo}
              refetchDetailRoom={refetch}
              externalTeachers={externalTeachers}
              setExternalTeachers={setExternalTeachers}
            />
          </TableCell>
        ) : null}

        {/* Attendances */}
        {roomInfo.date_session.map((session: IDateSession) => {
          let teacherAttendances = null;
          if (currentTeacherRecords && currentTeacherRecords.length > 0) {
            teacherAttendances = currentTeacherRecords.find(record => {
              //Este tiene que cambiar para leer del nuevo listado
              return record.teacher ? record.teacher.id === teacher.id : false;
            });
          }

          const attendanceForCurrentSession = teacherAttendances
            ? teacherAttendances.attendances.find(
                attendance =>
                  session.session_number === attendance.number_of_session
              )
            : null;

          return (
            <RenderTableCellTeacher
              key={`teacher-attendance-${teacher.id}-sessions-${session.session_number}`}
              teacher={teacher}
              session={session}
              teacherType={teacherType}
              attendanceForCurrentSession={attendanceForCurrentSession}
            />
          );
        })}
      </TableRow>
    );
  };

  const generalStyles: any = {
    fontFamily: 'Sofia Pro',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 12,
    borderBottom: 'none',
    textAlign: 'center'
  };

  const generalStylesEmptyRows: any = {
    pt: 10,
    pb: 7,
    background: '#fff',
    fontWeight: 400,
    fontFamily: 'Sofia Pro',
    color: colors.gris03,
    fontSize: 14,
    textAlign: 'center'
  };

  const RenderTableCellTeacher = ({
    key,
    teacher,
    session,
    attendanceForCurrentSession,
    teacherType
  }: {
    key: string;
    teacher: any;
    session: any;
    attendanceForCurrentSession: any;
    teacherType: 'teacherCTC' | 'AuxiliaryTeacher';
  }) => {
    let shouldDisable;
    if (teacherType === 'AuxiliaryTeacher') {
      shouldDisable =
        !session.state || user?.permission?.name === TEACHER_AUXILIARY;
    } else {
      shouldDisable =
        session.teacher_id !== teacher.id ||
        !session.state ||
        user?.permission?.name === TEACHER_CTC ||
        user?.permission?.name === TEACHER_AUXILIARY;
    }

    const addFill = shouldDisable
      ? 'transparent'
      : !attendanceForCurrentSession ||
        iconFillColors[attendanceForCurrentSession?.status] ||
        iconFillColors.default;

    const handleChangeSelect = (event: SelectChangeEvent<number>) => {
      if (attendanceForCurrentSession) {
        handleUpdateStateAttendance(event, attendanceForCurrentSession);
      } else {
        handleSubmitUniqueAttendance(
          event,
          session.session_number,
          roomInfo.id,
          teacher.id,
          teacherType
        );
      }
    };

    return (
      <TableCell
        component="th"
        scope="row"
        key={key}
        sx={{
          borderBottom: 'none',
          p: '0px 4px',
          textAlign: 'center'
        }}
      >
        <FormControl sx={{ m: 1 }} variant="standard" disabled={shouldDisable}>
          <Select
            labelId="demo-customized-select-label"
            disabled={shouldDisable}
            id="demo-customized-select"
            value={
              attendanceForCurrentSession
                ? attendanceForCurrentSession.status
                : 0
            }
            defaultValue={0}
            onChange={handleChangeSelect}
            sx={{
              '.MuiSelect-iconOpen': { paddingLeft: '16px' },
              '.MuiMenuItem-root.Mui-selected': { backgroundColor: '#F6F6F6' },
              '.MuiSelect-icon ': {
                fill: addFill,
                paddingRight: '16px'
              },
              color: session.state ? colors.gris03 : colors.gris02
            }}
            IconComponent={KeyboardArrowDownIcon}
            className={`table-attendance-teacher table-attendance-teacher__select ${getAttendanceClass(
              {
                session,
                teacher,
                attendanceForCurrentSession,
                teacherType
              }
            )}`}
          >
            {renderMenuItem({
              value: 0,
              message: '-',
              status: 'undefined',
              minWidth: 105
            })}
            {renderMenuItem({
              value: 1,
              message: 'A',
              status: 'attended',
              minWidth: 105
            })}
            <hr />
            {renderMenuItem({
              value: 2,
              message: 'T',
              status: 'delay'
            })}
            <hr />
            {renderMenuItem({
              value: 3,
              message: 'F',
              status: 'absence'
            })}
            <hr />
            {renderMenuItem({
              value: 4,
              message: 'J',
              status: 'justified'
            })}
            <hr />
            {renderMenuItem({
              value: 7,
              message: 'N/A',
              status: 'N/A'
            })}
            {teacherType === 'AuxiliaryTeacher' ? <hr /> : null}
            {teacherType === 'AuxiliaryTeacher'
              ? renderMenuItem({
                  value: 8,
                  message: 'S',
                  status: 'substitution'
                })
              : null}
          </Select>
        </FormControl>
      </TableCell>
    );
  };

  if (teacherType === 'teacherCTC' && roomInfo.teachers.length < 1) {
    return (
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                component="td"
                scope="row"
                sx={{ ...generalStylesEmptyRows }}
              >
                <span>No hay profesores Crack the Code en este salón</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else if (
    teacherType === 'AuxiliaryTeacher' &&
    roomInfo.auxiliar_teacher.length < 1
  ) {
    return (
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                component="td"
                scope="row"
                sx={{ ...generalStylesEmptyRows }}
              >
                <span>No hay profesores externos en este salón</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: 'none',
          '&::-webkit-scrollbar': { height: 12 },
          '&::-webkit-scrollbar-track': { backgroundColor: colors.gris02 },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: colors.gris03,
            borderRadius: 2
          }
        }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          {/* Header */}
          <TableHead>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  pt: 0,
                  background: '#fff',
                  minWidth: 400,
                  maxWidth: 400,
                  width: 400,
                  ...generalStyles
                }}
                className="room-details-teacher-attendance-tables-sticky"
              >
                {teacherType === 'teacherCTC'
                  ? 'Profesor Crack the Code'
                  : 'Profesor externo'}
              </TableCell>
              {teacherType === 'AuxiliaryTeacher' ? (
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    left: 432,
                    pt: 0,
                    background: '#fff',
                    minWidth: 120,
                    maxWidth: 120,
                    width: 120,
                    ...generalStyles
                  }}
                  className="room-details-teacher-attendance-tables-sticky"
                >
                  Credenciales
                </TableCell>
              ) : null}
              {roomInfo.date_session.length > 0
                ? roomInfo.date_session.map(session => {
                    return (
                      <TableCell
                        key={session.session_number}
                        component="th"
                        scope="row"
                        align="center"
                        sx={{
                          pt: 0,
                          color: session.state ? colors.gris05 : colors.gris02,
                          ...generalStyles
                        }}
                      >
                        Sesión {session.session_number}
                      </TableCell>
                    );
                  })
                : null}
            </TableRow>
          </TableHead>
          {/* Body */}
          <TableBody>
            {roomInfo && teacherType === 'teacherCTC'
              ? roomInfo.teachers.map((teacher: ITeacher) => (
                  <RenderTableRowTeacher
                    teacher={teacher}
                    teacherType="teacherCTC"
                  />
                ))
              : roomInfo && teacherType === 'AuxiliaryTeacher'
                ? roomInfo.auxiliar_teacher.map(
                    (teacher: IAuxiliaryTeacher) => (
                      <RenderTableRowTeacher
                        teacher={teacher}
                        teacherType="AuxiliaryTeacher"
                      />
                    )
                  )
                : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableAttendanceTeacherV2;
