import {
  Table,
  Paper,
  Select,
  MenuItem,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  FormControl,
  TableContainer
} from '@material-ui/core';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useState, useEffect, CSSProperties } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SelectChangeEvent } from '@mui/material';

import {
  postCreateStudentEvaluation,
  patchStudentEvaluation
} from '../../../services/evaluations';
import colors from '../../atoms/Colors';
import {
  IEvaluationStudent,
  IRoomDetailSimplified,
  IStudent
} from '../../../interfaces/room.interface';
import { RootState } from '../../../store/store';
/* import Select from '../../molecules/Select/Select'; */
import PERMISSIONS from '../../../constants/permission.constants';

import './tableEvaluationStudent.scss';
import EvaluationIndicator from '../../common/EvaluationIndicator/EvaluationIndicator';
import { TEACHER_AUXILIARY } from '../../../constants/usersType.constants';

interface IObjEvaluation {
  id: number;
  name: string;
}

interface Props {
  dataEvaluationStudent: IEvaluationStudent[];
  roomInfo: IRoomDetailSimplified;
  startDateEvaluation: string;
  endDateEvaluation: string;
}

const TableEvaluationStudentV2: React.FC<Props> = ({
  dataEvaluationStudent,
  roomInfo,
  startDateEvaluation,
  endDateEvaluation
}) => {
  const today = moment();
  const [selectOption, setSelectOption] = useState<string[][]>(
    Array(roomInfo?.students?.length)
      .fill(0)
      .map(() => new Array(6).fill(''))
  );
  const user = useSelector((state: RootState) => state.auth.user);
  const [disableSelect, setDisableSelect] = useState<boolean>(false);
  useEffect(() => {
    if (user) {
      const isAdmin = user?.permission[PERMISSIONS.roomListAll];
      const isAuxTeacher = user?.permission?.name === TEACHER_AUXILIARY;
      const isAfter =
        today.format('YYYY-MM-DD') >
        moment(endDateEvaluation).format('YYYY-MM-DD');
      const isDuring =
        moment(startDateEvaluation).format('YYYY-MM-DD') <=
          today.format('YYYY-MM-DD') &&
        today.format('YYYY-MM-DD') <=
          moment(endDateEvaluation).format('YYYY-MM-DD');

      const teacherId = roomInfo?.main_teacher?.id;

      if (!isAdmin && !isAuxTeacher && teacherId !== user?.id) {
        setDisableSelect(true);
      } else if (
        !isAdmin &&
        (isAuxTeacher || teacherId === user?.id) &&
        !isDuring
      ) {
        setDisableSelect(true);
      } else if (
        !isAdmin &&
        (teacherId === user?.id || isAuxTeacher) &&
        isAfter
      ) {
        setDisableSelect(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const objEvaluation: IObjEvaluation[] = [
    { id: 1, name: 'Logrado' },
    { id: 2, name: 'En Proceso' },
    { id: 3, name: 'En Inicio' }
  ];

  const handleEvaluation = (
    category: number,
    scale: number,
    student: number
  ) => {
    const categoryPositions: Record<number, number> = {
      1: 0,
      2: 3,
      3: 1,
      4: 4,
      5: 2,
      6: 5
    };

    const scaleTexts: Record<number, string> = {
      1: 'Logrado',
      2: 'En Proceso',
      3: 'En Inicio'
    };

    const categoryPosition = categoryPositions[category] || 0;
    const scaleText = scaleTexts[scale] || '';
    const inx = roomInfo?.students.findIndex(
      ({ id }: { id: number }) => id === student
    );

    selectOption[inx][categoryPosition] = scaleText;
  };

  useEffect(() => {
    if (dataEvaluationStudent?.length > 0) {
      dataEvaluationStudent?.forEach((value: IEvaluationStudent) => {
        handleEvaluation(value.category, value.scale, value.student);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEvaluationStudent]);

  const handleUpdate = async (payload: any) => {
    try {
      await patchStudentEvaluation(payload);
      toast.success('Se guardo correctamente la evaluación');
    } catch (err) {
      toast.error('No se pudo guardar la evaluación');
    }
  };

  const handleCreate = async (payload: any) => {
    try {
      await postCreateStudentEvaluation(roomInfo.id, payload);
      toast.success('Se guardo correctamente la evaluación');
    } catch (err) {
      toast.error('No se pudo guardar la evaluación');
    }
  };

  const statusColors: { [key: string]: string } = {
    Logrado: colors.colorStatusStarted,
    'En Inicio': colors.colorStatusFinished,
    'En Proceso': colors.colorStatusToStart,
    default: colors.gris05
  };
  const statusBGColors: { [key: string]: string } = {
    Logrado: colors.bgStatusStarted,
    'En Inicio': colors.bgStatusFinished,
    'En Proceso': colors.bgStatusToStart,
    default: colors.gris03
  };

  const optionSelection = (event: any, indx_r: number, indx: number): void => {
    const value = event.target.value;
    const categoryPositions: Record<number, number> = {
      0: 1,
      1: 3,
      2: 5,
      3: 2,
      4: 4,
      5: 6
    };

    const categoryPosition = categoryPositions[indx] || 0;

    const dataStudentQualification = dataEvaluationStudent.filter(
      (qualification: IEvaluationStudent) =>
        qualification.student === roomInfo?.students[indx_r]?.id &&
        qualification.category === categoryPosition
    );

    if (dataStudentQualification.length > 0) {
      const update = [
        {
          id: dataStudentQualification[0].id,
          room: roomInfo.id,
          student: roomInfo?.students[indx_r]?.id,
          category: categoryPosition,
          scale: objEvaluation.find((o: IObjEvaluation) => o.name === value)?.id
        }
      ];
      handleUpdate(update);
    } else {
      const create = {
        room: roomInfo.id,
        student: roomInfo?.students[indx_r]?.id,
        category: categoryPosition,
        scale: objEvaluation.find((o: IObjEvaluation) => o.name === value)?.id
      };
      handleCreate(create);
    }

    const newValue = selectOption[indx_r].map((opt: any, idx: number) =>
      idx === indx ? value : opt
    );

    setSelectOption(
      selectOption.map((op: any, ind: number) =>
        ind === indx_r ? newValue : op
      )
    );
  };

  const fullNameRowStyles: CSSProperties = {
    color: colors.gris05,
    width: 200,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontFamily: 'Sofia Pro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    position: 'sticky',
    textAlign: 'center'
  };

  const columnsEvaluations = [
    { label: 'Estudiante', width: 200 },
    { label: 'Participación en clase', width: 150 },
    { label: 'Resolución de problemas', width: 150 },
    { label: 'Comunicación', width: 150 },
    { label: 'Creatividad', width: 150 },
    { label: 'Pensamiento lógico', width: 150 },
    { label: 'Proyecto', width: 150 }
  ];
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 700,
          boxShadow: 'none',
          '&::-webkit-scrollbar': { height: 12 },
          '&::-webkit-scrollbar-track': { backgroundColor: colors.gris02 },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: colors.gris03,
            borderRadius: 2
          }
        }}
      >
        <Table
          sx={{ minWidth: 700, marginBottom: '0' }}
          aria-label="customized table"
        >
          {/* Header */}
          <TableHead>
            <TableRow>
              {columnsEvaluations.map((column, index) => (
                <TableCell
                  key={`columns-header${index}`}
                  component="th"
                  scope="row"
                  sx={{
                    minWidth: column.width,
                    width: column.width
                  }}
                  className="room-details-student-evaluation-tables"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {/* Body */}
          <TableBody>
            {roomInfo.students?.map(
              (row: IStudent, index_row: number) =>
                row.enrolment_status !== 'abandoned' && (
                  <TableRow key={row.full_name}>
                    {/* Student */}
                    <TableCell
                      scope="row"
                      component="th"
                      className="room-details-student-evaluation-tables"
                      sx={{
                        minWidth: columnsEvaluations[0].width,
                        width: columnsEvaluations[0].width,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <div style={fullNameRowStyles}>{row.full_name}</div>
                    </TableCell>
                    {/* Attendances */}
                    {[...Array(Number(6) || 0)].map((_, index) => {
                      const option = selectOption[index_row][index];

                      return (
                        <TableCell
                          key={index}
                          scope="row"
                          component="th"
                          sx={{
                            minWidth: columnsEvaluations[index + 1].width,
                            width: columnsEvaluations[index + 1].width
                          }}
                          className="room-details-student-evaluation-tables"
                        >
                          <div className="table-evaluation-student__container-select">
                            <FormControl disabled={disableSelect}>
                              <Select
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                value={option ? option : ''}
                                sx={{
                                  width: 140,
                                  height: 41,
                                  borderRadius: '0.375rem',

                                  fontWeight: 700,
                                  backgroundColor: statusBGColors[option],
                                  '.MuiMenuItem-root.Mui-selected': {
                                    backgroundColor: '#F6F6F6',
                                    fontWeight: 700,
                                    color: statusBGColors[option],
                                    border: `1px solid ${statusBGColors[option]}`,
                                    outline: `1px solid ${statusBGColors[option]}`
                                  },
                                  '.MuiSelect-icon ': {
                                    fill: statusColors[option],
                                    width: '1.8rem',
                                    height: '1.8rem',
                                    top: 'calc(50% - 0.9rem)'
                                  },
                                  '& .MuiSelect-select:focus': {
                                    border: 0,
                                    outline: 0
                                  },
                                  color: option
                                    ? statusColors[option]
                                    : colors.gris02
                                }}
                                IconComponent={KeyboardArrowDownIcon}
                                onChange={(event: SelectChangeEvent) =>
                                  optionSelection(event, index_row, index)
                                }
                              >
                                <MenuItem
                                  value={objEvaluation[2].name}
                                  sx={{ padding: '6px 16px' }}
                                >
                                  <EvaluationIndicator
                                    message={objEvaluation[2].name}
                                    status={objEvaluation[2]?.name}
                                  />
                                </MenuItem>
                                <hr
                                  style={{ marginBottom: '0', marginTop: '0' }}
                                />
                                <MenuItem
                                  value={objEvaluation[1].name}
                                  sx={{
                                    padding: '6px 16px',
                                    width: '100%'
                                  }}
                                >
                                  <EvaluationIndicator
                                    message={objEvaluation[1].name}
                                    status={objEvaluation[1]?.name}
                                  />
                                </MenuItem>
                                <hr
                                  style={{ marginBottom: '0', marginTop: '0' }}
                                />
                                <MenuItem
                                  value={objEvaluation[0].name}
                                  sx={{
                                    minWidth: 105,
                                    padding: '6px 16px',
                                    width: '100%'
                                  }}
                                >
                                  <EvaluationIndicator
                                    message={objEvaluation[0].name}
                                    status={objEvaluation[0]?.name}
                                  />
                                </MenuItem>
                                <hr
                                  style={{ marginBottom: '0', marginTop: '0' }}
                                />
                              </Select>
                            </FormControl>
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableEvaluationStudentV2;
