/* eslint-disable max-lines */
/* eslint-disable complexity */
import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import './roomDetailHeader.scss';
import {
  getListOverdueReasons,
  postOverdueContentReport
} from '../../../../../services/b2b/overdueContent';
import { RootState } from '../../../../../store/store';
import { PATCH } from '../../../../../services/api/api';
import { clearString } from '../../../../../utils/utils';
import { useModal } from '../../../../../hooks/useModal';
import colors from '../../../../../components/atoms/Colors';
import Link from '../../../../../components/molecules/Link/Link';
import SelectComp from '../../../../../components/molecules/SelectComp';
import IconArrow from '../../../../../components/atoms//icons/IconArrow';
import Typography from '../../../../../components/atoms/Typography/Typography';
import EditUrlSatisfaction from '../../EditUrlSatisfaction/EditUrlSatisfaction';
import { getHasPendingReport } from '../../../../../services/b2b/overdueContent';
import IconCopyFilled from '../../../../../components/atoms/icons/IconCopyFilled';
import ModalMessageAlert from '../../../../../components/molecules/ModalMessageAlert';
import {
  ADMIN,
  TEACHER_AUXILIARY
} from '../../../../../constants/usersType.constants';
import ButtonFilled from '../../../../../components/molecules/ButtonFilled/ButtonFilled';
import ButtonLineal from '../../../../../components/molecules/ButtonLineal/ButtonLineal';
import ButtonOutline from '../../../../../components/molecules/ButtonOutline/ButtonOutline';
import { AlertSnackbar } from '../../../../../components/common/AlertSnackbar/AlertSnackbar';
import AuxTeacherActionsModal from '../../../../../components/organism/AuxTeacherActionsModal';
import ModalReportOverdueContent from '../../../../../components/molecules/ModalReportOverdueContent';
import ModalDetailReportOverdueContent from '../../../../../components/molecules/ModalDetailReportOverdueContent';

interface Props {
  room?: any;
  refetch: () => void;
}

type QtyClases = {
  value: string;
  name: string;
};
type ReasonOverdue = {
  id: number;
  name: string;
};

const RoomDetailHeader: React.FC<Props> = ({ room, refetch }) => {
  const modalRef = useRef<any>(null);
  const user = useSelector((state: RootState) => state.auth.user);

  const isAdmin = user?.permission.name === ADMIN;
  const teacherAuxiliary = user?.permission?.name === TEACHER_AUXILIARY;

  const [isErrorAlert, setIsErrorAlert] = useState<boolean>(false);
  const [detailReportByRoom, setDetailReportByRoom] = useState();
  const [isPendingReport, setIsPendingReport] = useState<boolean>(false);
  const [qtyClases, setQtyClases] = useState<QtyClases>({
    value: '',
    name: ''
  });
  const [reasonOverdue, setReasonOverdue] = useState<ReasonOverdue>({
    id: 0,
    name: ''
  });
  const [listreasonOverdue, setListReasonOverdue] = useState<any[]>();
  const [externalTeachers, setExternalTeachers] = useState<any>([]);

  const {
    openModal: openModalReportContentDelay,
    closeModal: closeModalReportContentDelay,
    isOpenModal: isOpenModalReportContentDelay
  } = useModal();

  const {
    openModal: openModalAlert,
    closeModal: closeModalAlert,
    isOpenModal: isOpenModalAlert
  } = useModal();

  const {
    openModal: openModalDetailReport,
    closeModal: closeModalDetailReport,
    isOpenModal: isOpenModalDetailReport
  } = useModal();

  const dataClases = [
    { value: '2', name: '2 clases' },
    { value: '3', name: '3 clases' },
    { value: '+4', name: '4 o más clases' }
  ];

  useEffect(() => {
    hasPendingReport();
  }, []);

  const handleZoom = () => {
    if (!room?.online_meeting) {
      toast.error('No existe información de Zoom asociada');
      return;
    } else {
      teacherAuxiliary
        ? window.open(room?.online_meeting?.student_url, '_blank')
        : handleRefreshMeetingLink(); //We need to update Zoom Teacher URL because the Zoom Access Key (ZAK) has a validity of 2 hours since generation. More info here: https://developers.zoom.us/docs/meeting-sdk/auth/
    }
  };

  const handleRecordings = () => {
    if (room?.google_drive_url_share) {
      toast.success('URL de grabaciones copiado...');
      navigator.clipboard.writeText(room?.google_drive_url_share);
    } else toast.error('No existe URL de grabaciones');
  };

  const refreshMeetingLink = useMutation(
    (zoom_meeting_id: string) =>
      PATCH(`/meeting/${zoom_meeting_id}/refresh-start/`, { zoom_meeting_id }),
    { retry: false }
  );

  const handleRefreshMeetingLink = async () => {
    await toast.promise(
      refreshMeetingLink
        .mutateAsync(clearString(room?.online_meeting.external_id))
        .then(({ data: { start_url } }) => {
          setTimeout(() => {
            window.open(start_url, '_blank');
            refetch();
          }, 1000);
        }),
      {
        loading: 'Cargando...',
        success: 'Vamos a Zoom!',
        error: `Ha ocurrido un error. Inicie la clase con la cuenta <${room?.online_meeting.licensed_user_email}>`
      },
      { success: { icon: <InsertLinkIcon color="primary" /> } }
    );
  };

  const RenderExternalTeacher = ({
    id,
    name,
    last_name,
    index,
    email
  }: {
    id: any;
    name: any;
    last_name: any;
    index: any;
    email: any;
  }) => {
    const handleClickGetExtTeacher = () => {
      if (modalRef.current) {
        modalRef.current.getExternalTeacher({ email });
      }
    };

    return (
      <>
        <Typography
          type="paragraph2"
          variation="mobile"
          bold
          className="room-detail-header-v2__typography"
        >
          Profesor externo {index}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <Typography
            type="paragraph2"
            variation="mobile"
            className="room-detail-header-v2__typography"
          >
            {name} {last_name}
          </Typography>
          <IconButton style={{ padding: 0 }} onClick={handleClickGetExtTeacher}>
            <Tooltip title="Ver Detalle del profesor" arrow placement="right">
              <VisibilityIcon />
            </Tooltip>
          </IconButton>
        </div>
      </>
    );
  };
  const handleClickQtyClasses = async (option: any) => {
    setQtyClases(option);
  };

  const handleClickReasonsOverdue = async (option: any) => {
    setReasonOverdue(option);
  };
  const hasPendingReport = async () => {
    try {
      const { data } = await getHasPendingReport(room?.id);
      setDetailReportByRoom(data);
      if (data && data?.status === 'pending') {
        setIsPendingReport(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const listReasons = async () => {
    try {
      const response = await getListOverdueReasons();
      setListReasonOverdue(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendReportOverdue = async (
    roomId: number,
    count: string,
    reasonId: string | number
  ) => {
    try {
      const response = await postOverdueContentReport({
        roomId,
        count,
        reasonId
      });
      if (response.status === 201) {
        closeModalReport();
        openModalAlert();
        hasPendingReport();
      } else {
        setIsErrorAlert(true);
        setTimeout(() => {
          setIsErrorAlert(false);
        }, 5000);
      }
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      if (error) {
        setIsErrorAlert(true);
        setTimeout(() => {
          setIsErrorAlert(false);
        }, 5000);
      }
    }
  };

  const closeModalReport = () => {
    closeModalReportContentDelay();
    setQtyClases({ value: '', name: '' });
    setReasonOverdue({ id: 0, name: '' });
  };

  const handleClickAddExtTeacher = () => {
    if (modalRef.current) {
      modalRef.current.setInitialValuesExternalTeacher();
    }
  };

  return (
    <>
      <div className="room-detail-header-v2__content">
        <Link
          className="room-detail-header-v2__button-back"
          children="Regresar a Salones"
          to="/dashboard/rooms"
          iconLeft={<IconArrow className="room-detail-header-v2__icon-back" />}
        />
        <div className="room-detail-header-v2__container-title">
          <Typography
            className="room-detail-header-v2__title"
            type="h2"
            variation="mobile"
            bold
          >
            {room?.name}
          </Typography>
          {!isAdmin &&
          !isPendingReport &&
          user?.permission.name !== TEACHER_AUXILIARY ? (
            <ButtonOutline
              size="small"
              disabled={false}
              onClick={() => {
                openModalReportContentDelay();
                listReasons();
              }}
            >
              ⚠️ Reportar atraso de contenido
            </ButtonOutline>
          ) : null}
          {isPendingReport && (
            <div className="room-detail-header-v2__container-see-report">
              <Typography
                className="room-detail-header-v2__icon-see-report"
                type="paragraph2"
                variation="mobile"
                color={colors.black}
              >
                Reporte atraso de contenido
              </Typography>
              <ButtonLineal
                size="small"
                underline={false}
                onClick={() => openModalDetailReport()}
              >
                <Tooltip title="Ver reporte" arrow placement="right">
                  <RemoveRedEyeIcon
                    style={{ width: 20, height: 20 }}
                    fill="#000000"
                  />
                </Tooltip>
              </ButtonLineal>
            </div>
          )}
        </div>
        <div className="room-detail-header-v2__details">
          <div className="room-detail-header-v2__details-content-section">
            <div className="room-detail-header-v2__details-section-first-column">
              <Typography
                type="paragraph2"
                variation="mobile"
                bold
                className="room-detail-header-v2__typography"
              >
                Grupo
              </Typography>
              <div style={{ display: 'flex' }}>
                <Typography
                  type="paragraph2"
                  variation="mobile"
                  className="room-detail-header-v2__typography"
                >
                  {room.group.name}
                </Typography>
                {user?.permission?.name === ADMIN ? (
                  <Link
                    className="room-detail-header-v2__eye-icon"
                    to={`/dashboard/groups/${room?.group.id}/rooms`}
                    iconRight={
                      <Tooltip title="Ir al grupo" arrow placement="right">
                        <RemoveRedEyeIcon
                          style={{ width: 20, height: 20 }}
                          fill="#000000"
                        />
                      </Tooltip>
                    }
                    children={undefined}
                  />
                ) : null}
              </div>
              <Typography
                type="paragraph2"
                variation="mobile"
                bold
                className="room-detail-header-v2__typography"
              >
                Profesor CTC
              </Typography>
              <Typography
                type="paragraph2"
                variation="mobile"
                className="room-detail-header-v2__typography"
              >
                {room?.main_teacher.full_name}
              </Typography>
              {user?.permission?.name === TEACHER_AUXILIARY ? (
                <>
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    bold
                    className="room-detail-header-v2__typography"
                  >
                    Link a Zoom
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      className="room-detail-header-v2__typography"
                    >
                      {room?.online_meeting?.student_url}
                    </Typography>
                    <IconCopyFilled
                      style={{ paddingLeft: '8px' }}
                      onClick={() => {
                        if (room?.online_meeting?.student_url) {
                          toast.success('URL de Zoom copiado...');
                          navigator.clipboard.writeText(
                            room?.online_meeting.student_url
                          );
                        } else toast.error('No existe URL de Zoom');
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    bold
                    className="room-detail-header-v2__typography"
                  >
                    Fecha de inicio
                  </Typography>
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    className="room-detail-header-v2__typography"
                  >
                    {room?.group.start_date}
                  </Typography>
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    bold
                    className="room-detail-header-v2__typography"
                  >
                    Fecha de fin
                  </Typography>
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    className="room-detail-header-v2__typography"
                  >
                    {room?.end_date}
                  </Typography>
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    bold
                    className="room-detail-header-v2__typography"
                  >
                    Zoom ID
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      className="room-detail-header-v2__typography"
                    >
                      {room?.online_meeting?.external_id}
                    </Typography>
                    <IconCopyFilled
                      style={{ paddingLeft: '8px' }}
                      onClick={() => {
                        if (room?.online_meeting?.external_id) {
                          toast.success('Zoom ID copiado...');
                          navigator.clipboard.writeText(
                            room?.online_meeting.external_id
                          );
                        } else toast.error('No existe el Zoom ID');
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="room-detail-header-v2__details-section-second-column">
              <Typography
                type="paragraph2"
                variation="mobile"
                bold
                className="room-detail-header-v2__typography"
              >
                URL salón Moodle
              </Typography>
              <div style={{ display: 'flex' }}>
                <Typography
                  type="paragraph2"
                  variation="mobile"
                  className="room-detail-header-v2__typography"
                >
                  {`https://aprende.crackthecode.la/course/view.php?id=${room?.course_mdl_id}`}
                </Typography>
                <IconCopyFilled
                  style={{ paddingLeft: '8px' }}
                  onClick={() => {
                    if (room?.course_mdl_id) {
                      toast.success('URL salón Moodle copiado...');
                      navigator.clipboard.writeText(
                        `https://aprende.crackthecode.la/course/view.php?id=${room?.course_mdl_id}`
                      );
                    } else toast.error('No existe URL salón Moodle');
                  }}
                />
              </div>
              {user?.permission?.name === TEACHER_AUXILIARY ? (
                <>
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    bold
                    className="room-detail-header-v2__typography"
                  >
                    Fecha de inicio
                  </Typography>
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    className="room-detail-header-v2__typography"
                  >
                    {room?.group.start_date}
                  </Typography>
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    bold
                    className="room-detail-header-v2__typography"
                  >
                    Fecha de fin
                  </Typography>
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    className="room-detail-header-v2__typography"
                  >
                    {room?.end_date}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    bold
                    className="room-detail-header-v2__typography"
                  >
                    Licencia
                  </Typography>
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    className="room-detail-header-v2__typography"
                  >
                    {room?.online_meeting?.licensed_user_email}
                  </Typography>
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    bold
                    className="room-detail-header-v2__typography"
                  >
                    Contraseña de licencia
                  </Typography>
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    className="room-detail-header-v2__typography"
                  >
                    {room?.online_meeting?.licensed_user_password}
                  </Typography>
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    bold
                    className="room-detail-header-v2__typography"
                  >
                    URL de estudiante
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      className="room-detail-header-v2__typography"
                    >
                      {room?.online_meeting?.student_url}
                    </Typography>
                    <IconCopyFilled
                      style={{ paddingLeft: '8px' }}
                      onClick={() => {
                        if (room?.online_meeting?.student_url) {
                          toast.success('URL de Zoom copiado...');
                          navigator.clipboard.writeText(
                            room?.online_meeting.student_url
                          );
                        } else toast.error('No existe URL de Zoom');
                      }}
                    />
                  </div>
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    bold
                    className="room-detail-header-v2__typography"
                  >
                    Contraseña meet
                  </Typography>
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    className="room-detail-header-v2__typography"
                  >
                    {room?.online_meeting?.password}
                  </Typography>
                  <Typography
                    type="paragraph2"
                    variation="mobile"
                    bold
                    className="room-detail-header-v2__typography"
                  >
                    URL de satisfacción
                  </Typography>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      type="paragraph2"
                      variation="mobile"
                      className="room-detail-header-v2__typography"
                    >
                      {room?.url_satisfaction_survey}
                    </Typography>
                    <EditUrlSatisfaction
                      roomId={room?.id}
                      url_satisfaction_survey={room?.url_satisfaction_survey}
                      refetch={refetch}
                    />
                  </div>
                  <>
                    {externalTeachers.map((val: any, index: any) => {
                      return (
                        <RenderExternalTeacher
                          key={val.index}
                          name={val.name}
                          last_name={val.last_name}
                          id={val.id}
                          index={index + 1}
                          email={val.email}
                        />
                      );
                    })}
                  </>
                  {externalTeachers.length < 3 &&
                    room.business_type === 'B2B' && (
                      <ButtonLineal
                        size="small"
                        customStyles={{
                          fontSize: '14px',
                          padding: 0,
                          textAlign: 'left'
                        }}
                        onClick={handleClickAddExtTeacher}
                      >
                        Agregar profesor externo ({3 - externalTeachers.length})
                        +
                      </ButtonLineal>
                    )}
                </>
              )}
            </div>
          </div>
          <div className="room-detail-header-v2__details-buttons">
            <ButtonFilled
              size="small"
              onClick={() => handleZoom()}
              iconRight={<IconArrow fill="#FFFFFF" />}
            >
              Ir a Zoom
            </ButtonFilled>
            <ButtonOutline
              size="small"
              onClick={() => handleRecordings()}
              iconRight={<IconCopyFilled style={{ paddingTop: '3px' }} />}
            >
              Grabaciones
            </ButtonOutline>
          </div>
        </div>
        <AuxTeacherActionsModal
          ref={modalRef}
          roomInfo={room}
          refetchDetailRoom={refetch}
          externalTeachers={externalTeachers}
          setExternalTeachers={setExternalTeachers}
        />
      </div>
      <ModalReportOverdueContent
        width="lg"
        title="Reportar atraso de contenido"
        subtitle={
          <>
            Para que podamos ayudarte, selecciona la cantidad de clases
            atrasadas (redondeando para arriba) y el motivo principal. <br />{' '}
            <b>
              {' '}
              Recuerda que debes notificar sólo si van 2 o más clases con
              atraso.
            </b>
          </>
        }
        body={
          <form>
            <div className="room-detail-header-v2__modal-container">
              <Typography bold type="paragraph2" variation="mobile">
                Clases atrasadas*
              </Typography>
              <SelectComp
                size="small"
                onclickDefaultValue={(option: any) =>
                  handleClickQtyClasses(option)
                }
                options={dataClases}
                dividerItems
                value={qtyClases}
                placeholder="Seleccionar cantidad de clases atrasadas"
              />

              <Typography bold type="paragraph2" variation="mobile">
                Motivo principal*
              </Typography>
              <SelectComp
                size="small"
                onclickDefaultValue={(option: any) =>
                  handleClickReasonsOverdue(option)
                }
                options={listreasonOverdue}
                dividerItems
                value={reasonOverdue}
                placeholder="Seleccionar motivo principal"
              />
            </div>
          </form>
        }
        message="Se agrego correctamente el nuevo profesor..."
        isOpenModal={isOpenModalReportContentDelay}
        openModal={openModalReportContentDelay}
        closeModal={closeModalReportContentDelay}
        button1={
          <ButtonOutline size="default" onClick={closeModalReport}>
            Cancelar
          </ButtonOutline>
        }
        button2={
          <ButtonFilled
            size="default"
            disabled={!qtyClases?.value || !reasonOverdue?.id || !room?.id}
            onClick={
              qtyClases?.value && reasonOverdue?.id
                ? () =>
                    handleSendReportOverdue(
                      room?.id,
                      qtyClases?.value,
                      reasonOverdue?.id
                    )
                : () => {}
            }
          >
            Reportar
          </ButtonFilled>
        }
      />
      <ModalDetailReportOverdueContent
        information={detailReportByRoom}
        isAdmin={isAdmin}
        isStatusVisible={true}
        isOpenModal={isOpenModalDetailReport}
        openModal={openModalDetailReport}
        closeModal={closeModalDetailReport}
        button1={
          <ButtonFilled size="default" onClick={() => closeModalDetailReport()}>
            Aceptar
          </ButtonFilled>
        }
      />
      <ModalMessageAlert
        className="table-attendance-student__modal-alert"
        width="sm"
        align="center"
        title={
          <Typography bold type="h1" align="center" variation="mobile">
            ¡Gracias por reportarlo!
          </Typography>
        }
        isOpenModal={isOpenModalAlert}
        openModal={openModalAlert}
        closeModal={closeModalAlert}
        body={
          <Typography align="center" type="paragraph1" variation="mobile">
            Nos pondremos en contacto para encontrar una solución y poder llegar
            a dar todo el contenido.
          </Typography>
        }
        button1={
          <ButtonFilled fullWidth onClick={closeModalAlert}>
            Aceptar
          </ButtonFilled>
        }
      />
      {isErrorAlert ? (
        <AlertSnackbar
          message="Ocurrió un error al enviar la notificación de atraso de contenido. Vuelve a intentar"
          severity="error"
        />
      ) : null}
    </>
  );
};

export default RoomDetailHeader;
