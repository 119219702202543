/* eslint-disable complexity */
/* eslint-disable react/no-children-prop */
import moment from 'moment';
import { useSelector } from 'react-redux';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';

import { RootState } from '../../../store/store';
import colors from '../../../components/atoms/Colors';
import Select from '../../../components/molecules/Select/Select';
import PERMISSIONS from '../../../constants/permission.constants';
import IconCalendar from '../../../components/atoms/icons/IconCalendar';
import Typography from '../../../components/atoms/Typography/Typography';

export const ContractInfo: React.FC<{
  setFieldValue: any;
  values: any;
  handleChange: any;
  errors?: any;
  touched?: any;
  teacherId?: any;
  selectModality: any;
  isProfile?: boolean;
}> = ({
  setFieldValue,
  values,
  handleChange,
  errors,
  touched,
  teacherId,
  selectModality,
  isProfile
}) => {
  const userAdmin = useSelector((state: RootState) => state.auth.user);

  const objModality = [
    { id: 'locator', name: 'Locador' },
    { id: 'self', name: 'Por horas' },
    { id: 'part_time', name: 'Part Time' }
  ];

  const {
    optionSelectionModality,
    isOpenSelectModality,
    setIsOpenSelectModality,
    selectOptionModality,
    setSelectOptionModality
  } = selectModality;

  const RenderError = ({ error }: { error: any }) => {
    return (
      <Typography
        type="paragraph2"
        variation="mobile"
        className="input__alert"
        color={colors.warningColor}
      >
        {error}
      </Typography>
    );
  };

  const addModalitySelectClass =
    selectOptionModality?.name || values.modality_CTC
      ? 'teacher-profile__select'
      : 'teacher-profile__select-placeholder';

  const addModalitySelectErrorClass =
    errors.modality_CTC && touched.modality_CTC ? 'select__error' : '';

  return (
    <>
      <Typography
        type="h3"
        variation="mobile"
        bold
        className="teacher-availability teacher-availability__title"
      >
        Contrato
      </Typography>
      <br />
      <br />
      <div className="create-teacher-tabs__content-body">
        <div className="create-teacher-tabs__organization-information">
          <Typography bold type="paragraph3" variation="mobile">
            Tipo de contrato*
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {!values.modality_CTC}
              {values.modality_CTC === 'locator'
                ? 'Locador'
                : values.modality_CTC === 'part_time'
                  ? 'Part Time'
                  : values.modality_CTC === 'self'
                    ? 'Por Horas'
                    : '-'}
            </Typography>
          ) : (
            <div>
              <Select
                className={`${addModalitySelectClass} ${addModalitySelectErrorClass}`}
                setIsOpen={setIsOpenSelectModality}
                isOpen={isOpenSelectModality}
                size="default"
                dividerItems
                onclickDefaultValue={(event: any) => {
                  optionSelectionModality(event);
                  setFieldValue('modality_CTC', event.id);
                }}
                options={objModality}
                children={
                  values.modality_CTC
                    ? values.modality_CTC === 'locator'
                      ? 'Locador'
                      : values.modality_CTC === 'part_time'
                        ? 'Part Time'
                        : 'Por Horas'
                    : selectOptionModality?.name
                      ? selectOptionModality?.name
                      : 'Elige el tipo de contrato...'
                }
              />
              {errors.modality_CTC && touched.modality_CTC && (
                <RenderError error={errors.modality_CTC} />
              )}
            </div>
          )}
          {isProfile ? (
            <></>
          ) : (
            <>
              <Typography bold type="paragraph3" variation="mobile">
                Fecha fin de contrato
              </Typography>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <div className="teacher-profile__group-errorz">
                  <DatePicker
                    disabled={!userAdmin?.permission[PERMISSIONS.roomListAll]}
                    inputFormat="dd-MM-yyyy"
                    value={moment(values.date_end).valueOf()}
                    onChange={(value: any) => setFieldValue('date_end', value)}
                    disableMaskedInput
                    renderInput={(params: any) => (
                      <TextField
                        fullWidth
                        sx={{
                          input: {
                            padding: 0,
                            paddingLeft: '15px',
                            height: '40px'
                          },
                          '.MuiOutlinedInput-root': { borderRadius: '8px' },
                          fieldset: { border: '1px solid black !important' }
                        }}
                        {...params}
                      />
                    )}
                    components={{ OpenPickerIcon: IconCalendar }}
                  />
                </div>
              </LocalizationProvider>
            </>
          )}
        </div>
        <div className="create-teacher-tabs__organization-information">
          <Typography bold type="paragraph3" variation="mobile">
            Fecha de ingreso*
          </Typography>
          {isProfile ? (
            <Typography type="paragraph3" variation="mobile">
              {values.date_start || '-'}
            </Typography>
          ) : (
            <LocalizationProvider dateAdapter={DateAdapter}>
              <div className="teacher-profile__group-errorz">
                <DatePicker
                  disabled={!userAdmin?.permission[PERMISSIONS.roomListAll]}
                  inputFormat="dd-MM-yyyy"
                  value={moment(values?.date_start).valueOf()}
                  disableMaskedInput
                  onChange={(value: any) => setFieldValue('date_start', value)}
                  renderInput={(params: any) => (
                    <TextField
                      fullWidth
                      sx={{
                        input: {
                          padding: 0,
                          paddingLeft: '15px',
                          height: '40px'
                        },
                        '.MuiOutlinedInput-root': { borderRadius: '8px' },
                        fieldset: {
                          border:
                            errors.date_start && touched.date_start
                              ? '1px solid red !important'
                              : '1px solid black !important'
                        }
                      }}
                      {...params}
                    />
                  )}
                  components={{ OpenPickerIcon: IconCalendar }}
                />
                {errors.date_start && touched.date_start && (
                  <RenderError error={errors.date_start} />
                )}
              </div>
            </LocalizationProvider>
          )}
        </div>
      </div>
    </>
  );
};
