// React
import { useState } from 'react';

// React Query
import { useMutation, useQuery } from 'react-query';

// Yup
import * as Yup from 'yup';

// Formik
import { Formik, Form } from 'formik';

// API

// Moment
import moment from 'moment';

// Hot Toast
import { toast } from 'react-hot-toast';

// Interfaces

// Material UI
import Grid from '@mui/material/Grid';
import { DateTimePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import {
  Alert,
  CardMedia,
  Chip,
  Divider,
  Switch,
  TextField,
  Stack,
  LinearProgress
} from '@mui/material';
import DateAdapter from '@mui/lab/AdapterDateFns';
import UpdateIcon from '@mui/icons-material/Update';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';

import { ISlider } from '../../../../interfaces/slider.interface';
import { GET, PATCH, PATCHFILES } from '../../../../services/api/api';
import { getErrorMessage } from '../../../../utils/utils';

const EditSliderSchema = Yup.object().shape({
  title: Yup.string(),
  subtitle: Yup.string(),
  page: Yup.string().required('Requerido'),
  order_in_page: Yup.number().required('Requerido'),

  published_at: Yup.date().nullable(true),
  archived_at: Yup.date()
    .nullable(true)
    .min(Yup.ref('published_at'), 'ARCHIVADO NO puede ser antes que PUBLICADO'),

  is_primary_button_enabled: Yup.boolean(),
  primary_button_text: Yup.string().required('Requerido'),
  primary_button_url: Yup.string().required('Requerido'),
  primary_button_background_color: Yup.string().required('Requerido'),
  primary_button_text_color: Yup.string().required('Requerido'),

  is_secondary_button_enabled: Yup.boolean(),
  secondary_button_text: Yup.string(),
  secondary_button_url: Yup.string(),
  secondary_button_background_color: Yup.string(),
  secondary_button_text_color: Yup.string()
});

const UploadImageSchema = Yup.object().shape({
  cover: Yup.mixed().required(),
  cover_mobile: Yup.mixed()
});

interface Props {
  sliderId: number;
  refetch: () => {};
  onClose: () => void;
}

const EditSlider: React.FC<Props> = ({ sliderId, refetch, onClose }) => {
  const [isEdited, setIsEdited] = useState<boolean>(false);

  const {
    data: slider,
    error: errorSlider,
    isError: isErrorSlider,
    isLoading: isLoadingSlider,
    refetch: refetchSlider
  } = useQuery(
    ['slider', sliderId.toString()],
    () => GET<ISlider>(`/sliders/${sliderId}/`),
    { retry: false }
  );

  const editSlider = useMutation(
    (slider: ISlider) => PATCH(`/sliders/${sliderId}/`, slider),
    { retry: false }
  );

  const uploadImage = useMutation(
    (image: any) => PATCHFILES(`/sliders/media/${sliderId}/`, image),
    { retry: false }
  );

  if (isLoadingSlider || !slider) return <LinearProgress />;
  if (isErrorSlider) return <div>Error! {JSON.stringify(errorSlider)}</div>;

  const initialValues: ISlider = {
    title: slider.title,
    subtitle: slider.subtitle,
    page: slider.page,
    order_in_page: slider.order_in_page,

    published_at: slider.published_at && moment(slider.published_at).toDate(),
    archived_at: slider.archived_at && moment(slider.archived_at).toDate(),
    is_published: slider.is_published,

    is_primary_button_enabled: slider.is_primary_button_enabled,
    primary_button_text: slider.primary_button_text,
    primary_button_url: slider.primary_button_url,
    primary_button_background_color: slider.primary_button_background_color,
    primary_button_text_color: slider.primary_button_text_color,

    is_secondary_button_enabled: slider.is_secondary_button_enabled,
    secondary_button_text: slider.secondary_button_text,
    secondary_button_url: slider.secondary_button_url,
    secondary_button_background_color: slider.secondary_button_background_color,
    secondary_button_text_color: slider.secondary_button_text_color,

    cover: slider.cover,
    cover_mobile: slider.cover_mobile
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={!isEdited ? EditSliderSchema : UploadImageSchema}
      onSubmit={async values => {
        if (isEdited) {
          const bodyFormData = new FormData();
          bodyFormData.append('cover', values.cover);
          bodyFormData.append('cover_mobile', values.cover_mobile);

          await toast.promise(
            uploadImage.mutateAsync(bodyFormData),
            {
              loading: 'Cargando...',
              success: `Imagen Añadida`,
              error: 'Error'
            },
            { success: { icon: <UpdateIcon color="primary" /> } }
          );

          refetchSlider();
          refetch();
          onClose();
        } else {
          const data = {
            title: values.title,
            subtitle: values.subtitle,
            page: values.page,
            order_in_page: values.order_in_page,

            published_at: values.published_at,
            archived_at: values.archived_at,
            is_published: values.is_published,

            is_primary_button_enabled: values.is_primary_button_enabled,
            primary_button_text: values.primary_button_text,
            primary_button_url: values.primary_button_url,
            primary_button_background_color:
              values.primary_button_background_color,
            primary_button_text_color: values.primary_button_text_color,

            is_secondary_button_enabled: values.is_secondary_button_enabled,
            secondary_button_text: values.secondary_button_text,
            secondary_button_url: values.secondary_button_url,
            secondary_button_background_color:
              values.secondary_button_background_color,
            secondary_button_text_color: values.secondary_button_text_color
          };

          await toast.promise(
            editSlider.mutateAsync(data!).then(() => setIsEdited(true)),
            {
              loading: 'Cargando...',
              success: `Slider Editado`,
              error: 'Error'
            },
            { success: { icon: <UpdateIcon color="primary" /> } }
          );

          refetchSlider();
          refetch();
        }
      }}
    >
      {({
 errors, touched, values, handleChange, setFieldValue 
}) => (
        <Form>
          {/* Information */}
          <Grid item container rowGap={2} xs={12}>
            {/* Details */}
            <Grid item container rowGap={2} xs={12}>
              {/* Header */}
              <Grid item container columnSpacing={2} rowGap={2} xs={12}>
                {/* Title */}
                <Grid item xs={6}>
                  <TextField
                    autoFocus
                    fullWidth
                    id="title"
                    type="text"
                    label="Título"
                    variant="outlined"
                    value={values.title}
                    onChange={handleChange}
                    disabled={!!isEdited}
                  />
                  {errors.title && touched.title && (
                    <Alert severity="error">
                      {getErrorMessage(errors.title)}
                    </Alert>
                  )}
                </Grid>

                {/* Subtitle */}
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="subtitle"
                    type="text"
                    label="Subtítulo"
                    variant="outlined"
                    value={values.subtitle}
                    onChange={handleChange}
                    disabled={!!isEdited}
                  />
                  {errors.subtitle && touched.subtitle && (
                    <Alert severity="error">{errors.subtitle}</Alert>
                  )}
                </Grid>

                {/* Page */}
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="page"
                    type="text"
                    disabled
                    label="Pagina"
                    variant="outlined"
                    value={values.page}
                    onChange={handleChange}
                  />
                  {errors.page && touched.page && (
                    <Alert severity="error">{errors.page}</Alert>
                  )}
                </Grid>

                {/* Order_in_page */}
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="order_in_page"
                    type="number"
                    label="Orden de slider"
                    variant="outlined"
                    value={values.order_in_page}
                    onChange={handleChange}
                  />
                  {errors.order_in_page && touched.order_in_page && (
                    <Alert severity="error">{errors.order_in_page}</Alert>
                  )}
                </Grid>

                {/* published_at | archived_at */}
                <Grid item container columnSpacing={2} xs={12}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    {/* published_at */}
                    <Grid item xs={6}>
                      <DateTimePicker
                        label="Publicado"
                        inputFormat="yyyy-MM-dd"
                        value={values.published_at}
                        disableMaskedInput
                        ampm={false}
                        onChange={(value: any) =>
                          setFieldValue('published_at', value)
                        }
                        renderInput={(params: any) => (
                          <TextField fullWidth {...params} />
                        )}
                        disabled={!!isEdited}
                      />
                      {errors.published_at && touched.published_at && (
                        <Alert severity="error">
                          {getErrorMessage(errors.published_at)}
                        </Alert>
                      )}
                    </Grid>

                    {/* archived_at */}
                    <Grid item xs={6}>
                      <DateTimePicker
                        label="Archivado"
                        inputFormat="yyyy-MM-dd"
                        value={values.archived_at}
                        disableMaskedInput
                        minDate={values.published_at}
                        ampm={false}
                        onChange={(value: any) =>
                          setFieldValue('archived_at', value)
                        }
                        renderInput={(params: any) => (
                          <TextField fullWidth {...params} />
                        )}
                        disabled={!!isEdited}
                      />
                      {errors.archived_at && touched.archived_at && (
                        <Alert severity="error">
                          {getErrorMessage(errors.archived_at)}
                        </Alert>
                      )}
                    </Grid>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12}>
                  {values.is_published ? (
                    <Alert variant="standard" severity="success">
                      El grupo esta publicado
                    </Alert>
                  ) : (
                    <Alert variant="standard" severity="warning">
                      El grupo esta privado
                    </Alert>
                  )}
                </Grid>
              </Grid>

              {/* Primary Button || Secondary Button */}
              <Grid item container columnSpacing={2} xs={12}>
                {/* Primary Button */}
                <Grid item container rowGap={2} xs={6}>
                  {/* Header */}
                  <Grid item xs={12}>
                    <Divider>
                      <Chip
                        variant="outlined"
                        label="PRIMARY BUTTON"
                        disabled={!!isEdited}
                      />
                    </Divider>
                  </Grid>

                  {/* Is_primary_button_enabled */}
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Switch
                        checked={values.is_primary_button_enabled}
                        color="success"
                        onChange={e =>
                          setFieldValue(
                            'is_primary_button_enabled',
                            e.target.checked
                          )
                        }
                        disabled={!!isEdited}
                      />
                      {values.is_primary_button_enabled ? (
                        <Chip
                          icon={<VisibilityIcon />}
                          color="success"
                          label="Boton Visible"
                          disabled={!!isEdited}
                        />
                      ) : (
                        <Chip
                          icon={<VisibilityOffIcon />}
                          color="warning"
                          label="Boton Oculto"
                          disabled={!!isEdited}
                        />
                      )}
                    </Stack>
                  </Grid>

                  {/* Primary_button_text */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="primary_button_text"
                      type="text"
                      label="Texto"
                      variant="outlined"
                      value={values.primary_button_text}
                      onChange={handleChange}
                      disabled={!!isEdited}
                    />
                    {errors.primary_button_text &&
                      touched.primary_button_text && (
                        <Alert severity="error">
                          {errors.primary_button_text}
                        </Alert>
                      )}
                  </Grid>

                  {/* Primary_button_url */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="primary_button_url"
                      type="url"
                      label="URL"
                      variant="outlined"
                      value={values.primary_button_url}
                      onChange={handleChange}
                      disabled={!!isEdited}
                    />
                    {errors.primary_button_url &&
                      touched.primary_button_url && (
                        <Alert severity="error">
                          {errors.primary_button_url}
                        </Alert>
                      )}
                  </Grid>

                  {/* Primary_button_background_color */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="primary_button_background_color"
                      type="color"
                      label="Color del boton"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={values.primary_button_background_color}
                      onChange={handleChange}
                      disabled={!!isEdited}
                    />
                    {errors.primary_button_background_color &&
                      touched.primary_button_background_color && (
                        <Alert severity="error">
                          {errors.primary_button_background_color}
                        </Alert>
                      )}
                  </Grid>

                  {/* Primary_button_text_color */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="primary_button_text_color"
                      type="color"
                      label="Color del texto"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={values.primary_button_text_color}
                      onChange={handleChange}
                      disabled={!!isEdited}
                    />
                    {errors.primary_button_text_color &&
                      touched.primary_button_text_color && (
                        <Alert severity="error">
                          {errors.primary_button_text_color}
                        </Alert>
                      )}
                  </Grid>
                </Grid>

                {/* Secondary Button */}
                <Grid item container rowGap={2} xs={6}>
                  {/* Header */}
                  <Grid item xs={12}>
                    <Divider>
                      <Chip
                        variant="outlined"
                        label="SECONDARY BUTTON"
                        disabled={!!isEdited}
                      />
                    </Divider>
                  </Grid>

                  {/* Is_secondary_button_enabled */}
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Switch
                        checked={values.is_secondary_button_enabled}
                        color="success"
                        onChange={e =>
                          setFieldValue(
                            'is_secondary_button_enabled',
                            e.target.checked
                          )
                        }
                        disabled={!!isEdited}
                      />
                      {values.is_secondary_button_enabled ? (
                        <Chip
                          icon={<VisibilityIcon />}
                          color="success"
                          label="Boton Visible"
                          disabled={!!isEdited}
                        />
                      ) : (
                        <Chip
                          icon={<VisibilityOffIcon />}
                          color="warning"
                          label="Boton Oculto"
                          disabled={!!isEdited}
                        />
                      )}
                    </Stack>
                  </Grid>

                  {/* Secondary_button_text */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="secondary_button_text"
                      type="text"
                      label="Texto"
                      variant="outlined"
                      value={values.secondary_button_text}
                      onChange={handleChange}
                      disabled={!!isEdited}
                    />
                    {errors.secondary_button_text &&
                      touched.secondary_button_text && (
                        <Alert severity="error">
                          {errors.secondary_button_text}
                        </Alert>
                      )}
                  </Grid>

                  {/* Secondary_button_url */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="secondary_button_url"
                      type="url"
                      label="URL"
                      variant="outlined"
                      value={values.secondary_button_url}
                      onChange={handleChange}
                      disabled={!!isEdited}
                    />
                    {errors.secondary_button_url &&
                      touched.secondary_button_url && (
                        <Alert severity="error">
                          {errors.secondary_button_url}
                        </Alert>
                      )}
                  </Grid>

                  {/* Secondary_button_background_color */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="secondary_button_background_color"
                      type="color"
                      label="Color del boton"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={values.secondary_button_background_color}
                      onChange={handleChange}
                      disabled={!!isEdited}
                    />
                    {errors.secondary_button_background_color &&
                      touched.secondary_button_background_color && (
                        <Alert severity="error">
                          {errors.secondary_button_background_color}
                        </Alert>
                      )}
                  </Grid>

                  {/* Secondary_button_text_color */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="secondary_button_text_color"
                      type="color"
                      label="Color del texto"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={values.secondary_button_text_color}
                      onChange={handleChange}
                      disabled={!!isEdited}
                    />
                    {errors.secondary_button_text_color &&
                      touched.secondary_button_text_color && (
                        <Alert severity="error">
                          {errors.secondary_button_text_color}
                        </Alert>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Upload Images */}
            <Grid item container rowGap={2} xs={12}>
              {/* Header */}
              <Grid item xs={12}>
                <Divider textAlign="center">
                  <Chip
                    label="IMAGENES"
                    variant="outlined"
                    color="primary"
                    icon={<ViewCarouselIcon />}
                    disabled={!isEdited}
                  />
                </Divider>
              </Grid>

              {/* Container */}
              <Grid item container columnSpacing={2} rowGap={2} xs={12}>
                {/* Cover Desktop */}
                <Grid item xs={6}>
                  <Stack direction="column" rowGap={1.5}>
                    <Chip
                      label="Cover Desktop"
                      variant="outlined"
                      icon={<DesktopWindowsIcon />}
                      disabled={!isEdited}
                    />
                    <input
                      id="cover"
                      name="cover"
                      type="file"
                      onChange={(e: any) => {
                        setFieldValue('cover', e.target.files[0]);
                      }}
                      disabled={!isEdited}
                    />
                    {errors.cover && touched.cover && (
                      <Alert severity="error">
                        {getErrorMessage(errors.cover)}
                      </Alert>
                    )}
                  </Stack>
                </Grid>

                {/* Cover Mobile */}
                <Grid item xs={6}>
                  <Stack direction="column" rowGap={1.5}>
                    <Chip
                      label="Cover Mobile"
                      variant="outlined"
                      icon={<PhoneIphoneIcon />}
                      disabled={!isEdited}
                    />
                    <input
                      id="cover_mobile"
                      name="cover_mobile"
                      type="file"
                      onChange={(e: any) => {
                        setFieldValue('cover_mobile', e.target.files[0]);
                      }}
                      disabled={!isEdited}
                    />
                    {errors.cover_mobile && touched.cover_mobile && (
                      <Alert severity="error">
                        {getErrorMessage(errors.cover_mobile)}
                      </Alert>
                    )}
                  </Stack>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                {values.cover && (
                  <CardMedia
                    component="img"
                    height="140"
                    image={
                      typeof values.cover === 'object'
                        ? URL.createObjectURL(values.cover)
                        : values.cover
                    }
                    sx={{ objectFit: 'contain' }}
                  />
                )}
              </Grid>

              <Grid item xs={6}>
                {values.cover_mobile && (
                  <CardMedia
                    component="img"
                    height="140"
                    image={
                      typeof values.cover_mobile === 'object'
                        ? URL.createObjectURL(values.cover_mobile)
                        : values.cover_mobile
                    }
                    sx={{ objectFit: 'contain' }}
                  />
                )}
              </Grid>
            </Grid>

            {/* Edit */}
            <Grid item container rowGap={2} xs={12}>
              {/* Header */}
              <Grid item xs={12}>
                <Divider />
              </Grid>

              {/* Button */}
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <LoadingButton
                    fullWidth
                    loading={editSlider.isLoading}
                    variant="contained"
                    type="submit"
                    startIcon={<UpdateIcon />}
                  >
                    {isEdited ? 'Enviar Imagenes' : 'Editar'}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default EditSlider;
