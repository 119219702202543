import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useState, useEffect, useRef } from 'react';

import AttendanceIndicator from '../../common/AttendanceIndicator/AttendanceIndicator';

interface Props {
  options: any;
  key: any;
  labelId: any;
  id: any;
  value: any;
  defaultValue: any;
  MenuProps: any;
  sx: any;
  IconComponent: any;
  className: any;
  onChange: any;
}

export const LazySelect: React.FC<Props> = ({
  options,
  key,
  labelId,
  id,
  value,
  defaultValue,
  MenuProps,
  sx,
  IconComponent,
  className,
  onChange,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<any>();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  return (
    <div ref={ref}>
      {isVisible ? (
        <Select
          key={key}
          labelId={labelId}
          id={id}
          value={value}
          defaultValue={defaultValue}
          MenuProps={MenuProps}
          sx={sx}
          IconComponent={IconComponent}
          className={className}
          onChange={onChange}
          {...props}
        >
          <MenuItem
            key={`menu-item-${key}`}
            value={0}
            sx={{ '.Mui-selected': { backgroundColor: '#F6F6F6' } }}
            style={{
              paddingLeft: '16px',
              paddingTop: '6px',
              paddingBottom: '6px'
            }}
          >
            <em>-</em>
          </MenuItem>
          {options.map((option: any) => (
            <MenuItem
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              sx={option.sx}
            >
              {option.label}
              <AttendanceIndicator
                message={option.message}
                status={option.status}
              />
            </MenuItem>
          ))}
        </Select>
      ) : (
        <div>Cargando...</div>
      )}
    </div>
  );
};
