import { useState, useEffect } from 'react';

import { AppDispatch, useAppDispatch } from '../store/store';
import { getAttendanceByStudentFromApi } from '../slices/attendanceSlice/attendanceSlice';

const useAttendanceByStudent = ({
  roomId,
  shouldRefetch
}: {
  roomId: number;
  shouldRefetch?: boolean;
}) => {
  const dispatch: AppDispatch = useAppDispatch();
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getAttendance = async () => {
    try {
      return await dispatch(
        getAttendanceByStudentFromApi({ id: Number(roomId) })
      );
    } catch (error) {
      setIsError(true);
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAttendance();
        setData(response?.payload?.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching project:', error);
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId, shouldRefetch]);

  return { data, isLoading, isError };
};

export default useAttendanceByStudent;
