/* eslint-disable max-lines */
/* eslint-disable react/no-children-prop */
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box } from '@material-ui/system';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { Autocomplete, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';

import colors from '../../../../components/atoms/Colors';
import Input from '../../../../components/molecules/Input';
import { countries } from '../../../../constants/countries';
import Link from '../../../../components/molecules/Link/Link';
import Select from '../../../../components/molecules/Select/Select';
import IconCalendar from '../../../../components/atoms/icons/IconCalendar';
import Typography from '../../../../components/atoms/Typography/Typography';
import IconArrowDown from '../../../../components/atoms/icons/IconArrowDown';

export const StudentPersonalInfo: React.FC<{
  isDetail?: boolean;
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  setFieldValue: any;
  isOpenSelectGender: any;
  setIsOpenSelectGender: any;
  selectOptionGender: any;
  setSelectOptionGender: any;
  optionSelectionGender: any;
  genderOptions: any;
  guardians: any;
  student: any;
  selectOptionDocumentType: any;
  setSelectOptionDocumentType: any;
  isOpenSelectDocumentType: any;
  setIsOpenSelectDocumentType: any;
  optionSelectionDocumentType: any;
  documentTypeOptions: any;
  debouncedCallback: any;
  isCreatePath: any;
}> = ({
  isDetail,
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  isOpenSelectGender,
  setIsOpenSelectGender,
  selectOptionGender,
  setSelectOptionGender,
  optionSelectionGender,
  genderOptions,
  student,
  guardians,
  selectOptionDocumentType,
  setSelectOptionDocumentType,
  isOpenSelectDocumentType,
  setIsOpenSelectDocumentType,
  optionSelectionDocumentType,
  documentTypeOptions,
  debouncedCallback,
  isCreatePath
}) => {
  const RenderError = ({ error }: { error: any }) => {
    return (
      <Typography
        type="paragraph2"
        variation="mobile"
        className="input__alert"
        color={colors.warningColor}
      >
        {error}
      </Typography>
    );
  };
  const CustomIconCalendar = () => {
    return (
      <IconCalendar
        fill={errors?.birthdate ? colors.warningColor : colors.black}
      />
    );
  };

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedGuardian, setSelectedGuardian] = useState<any>(null);

  useEffect(() => {
    if (values?.country_student) {
      const country = countries.find(
        item => item.code === values.country_student
      );
      setSelectedCountry(country || null);
    } else {
      setSelectedCountry(null);
    }
  }, [values]);

  useEffect(() => {
    if (values.guardian) {
      const guardian = guardians.find(
        (item: any) => item?.id === values.guardian
      );
      setSelectedGuardian(guardian || null);
    }
  }, [values, guardians]);

  const handleGuardianChange = (event: any, newValue: any) => {
    setSelectedGuardian(newValue);
    setFieldValue('guardian', newValue?.id || '');
  };

  return (
    <>
      <Typography
        type="h3"
        variation="mobile"
        bold
        className="teacher-availability teacher-availability__title"
      >
        Información general
      </Typography>
      <br />
      <br />
      <div className="detail-edit-students__content-body">
        <div className="detail-edit-students__student-information">
          <>
            <Typography bold type="paragraph4" variation="mobile">
              Nombres{!isDetail && '*'}
            </Typography>
            {isDetail ? (
              <Typography type="paragraph4" variation="mobile">
                {values?.first_name || '-'}
              </Typography>
            ) : (
              <div>
                <Input
                  value={values?.first_name}
                  onChange={handleChange}
                  placeholder="Ingresa los nombres del estudiante"
                  name="first_name"
                  required
                  isError={errors?.first_name}
                  hideErrorText={true}
                  error={errors?.first_name ? errors?.first_name : ''}
                />
              </div>
            )}
          </>
          <>
            <Typography bold type="paragraph4" variation="mobile">
              Fecha de nacimiento
            </Typography>
            {isDetail ? (
              <Typography type="paragraph4" variation="mobile">
                {values?.birthdate || '-'}
              </Typography>
            ) : (
              <>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <div>
                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      disableMaskedInput
                      value={
                        values.birthdate
                          ? moment(values.birthdate).valueOf()
                          : null
                      }
                      clearable
                      onChange={(value: any) =>
                        setFieldValue('birthdate', value)
                      }
                      renderInput={(params: any) => (
                        <TextField
                          fullWidth
                          sx={{
                            input: {
                              padding: 0,
                              paddingLeft: '15px',
                              height: '40px'
                            },
                            '.MuiOutlinedInput-root': { borderRadius: '8px' },
                            fieldset: {
                              border: errors.birthdate
                                ? '1px solid red !important'
                                : '1px solid black !important'
                            }
                          }}
                          {...params}
                        />
                      )}
                      components={{ OpenPickerIcon: CustomIconCalendar }}
                    />
                    {errors.birthdate && (
                      <RenderError error={errors.birthdate} />
                    )}
                  </div>
                </LocalizationProvider>
              </>
            )}
          </>
          <>
            <Typography bold type="paragraph4" variation="mobile">
              Género{!isDetail && '*'}
            </Typography>
            {isDetail ? (
              <Typography type="paragraph4" variation="mobile">
                {values?.gender === 'male'
                  ? 'Masculino'
                  : values?.gender === 'female'
                    ? 'Femenino'
                    : values?.gender === 'unspecified'
                      ? 'No especifica'
                      : '-'}
              </Typography>
            ) : (
              <div>
                <Select
                  className="teacher-profile__select"
                  setIsOpen={setIsOpenSelectGender}
                  isOpen={isOpenSelectGender}
                  size="default"
                  dividerItems
                  onclickDefaultValue={(event: any) => {
                    optionSelectionGender(event);
                    setFieldValue('gender', event?.id);
                  }}
                  error={errors.gender}
                  options={genderOptions}
                  children={
                    values?.gender
                      ? values?.gender === 'male'
                        ? 'Masculino'
                        : values?.gender === 'female'
                          ? 'Femenino'
                          : 'No especifica'
                      : selectOptionGender?.name
                        ? selectOptionGender?.name
                        : 'Selecciona el género'
                  }
                />
                {errors.gender && <RenderError error={errors.gender} />}
              </div>
            )}
          </>
          <>
            <Typography bold type="paragraph4" variation="mobile">
              Tipo de documento
            </Typography>
            {isDetail ? (
              <Typography type="paragraph4" variation="mobile">
                {values.doc_type === 'DNI'
                  ? 'DNI'
                  : values.doc_type === 'cedula'
                    ? 'Cédula'
                    : values.doc_type === 'passport'
                      ? 'Pasaporte'
                      : values.doc_type === 'CURP'
                        ? 'CURP'
                        : values.doc_type === 'alien-certificate'
                          ? 'Carnet de Extranjería'
                          : values.doc_type === 'CPP'
                            ? 'CPP'
                            : values.doc_type === 'NES'
                              ? 'Número establecido por la Secretaría'
                              : values.doc_type === 'PEP'
                                ? 'Permiso especial de Permanencia'
                                : values.doc_type === 'PPT'
                                  ? 'Permiso de Protección Temporal'
                                  : values.doc_type === 'RC'
                                    ? 'Registro Civil de Nacimiento'
                                    : values.doc_type === 'TI'
                                      ? 'Tarjeta de Identidad'
                                      : values.doc_type === 'CE'
                                        ? 'Cédula de Extranjería'
                                        : '-'}
              </Typography>
            ) : (
              <div>
                <Select
                  setIsOpen={setIsOpenSelectDocumentType}
                  isOpen={isOpenSelectDocumentType}
                  size="default"
                  dividerItems
                  onclickDefaultValue={(event: any) => {
                    optionSelectionDocumentType(event);
                    setFieldValue('doc_type', event.id);
                  }}
                  options={documentTypeOptions}
                  children={
                    values.doc_type
                      ? values.doc_type === 'DNI'
                        ? 'DNI'
                        : values.doc_type === 'cedula'
                          ? 'Cédula'
                          : values.doc_type === 'passport'
                            ? 'Pasaporte'
                            : values.doc_type === 'CURP'
                              ? 'CURP'
                              : values.doc_type === 'alien-certificate'
                                ? 'Carnet de Extranjería'
                                : values.doc_type === 'CPP'
                                  ? 'CPP'
                                  : values.doc_type === 'NES'
                                    ? 'Número establecido por la Secretaría'
                                    : values.doc_type === 'PEP'
                                      ? 'Permiso especial de Permanencia'
                                      : values.doc_type === 'PPT'
                                        ? 'Permiso de Protección Temporal'
                                        : values.doc_type === 'RC'
                                          ? 'Registro Civil de Nacimiento'
                                          : values.doc_type === 'TI'
                                            ? 'Tarjeta de Identidad'
                                            : values.doc_type === 'CE'
                                              ? 'Cédula de Extranjería'
                                              : 'Elige el tipo de documento...'
                      : selectOptionDocumentType?.name
                        ? selectOptionDocumentType?.name
                        : 'Elige el tipo de documento...'
                  }
                />
                {errors.doc_type && <RenderError error={errors.doc_type} />}
              </div>
            )}
          </>
          <>
            <Typography bold type="paragraph4" variation="mobile">
              País{!isDetail && '*'}
            </Typography>
            {isDetail ? (
              <Typography type="paragraph4" variation="mobile">
                {countries.find(
                  country => country.code === values?.country_student
                )?.label || '-'}
              </Typography>
            ) : (
              <>
                <div>
                  <Autocomplete
                    className="detail-edit-students__student-information--country-dropdown"
                    fullWidth
                    id="country_student"
                    options={countries}
                    autoHighlight
                    value={selectedCountry}
                    onChange={(_e, value: any) => {
                      setSelectedCountry(value);
                      setFieldValue('country_student', value?.code || '');
                    }}
                    getOptionLabel={option => option.label}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label} ({option.code})
                      </Box>
                    )}
                    renderInput={(params: any) => (
                      <TextField
                        className="textfield-student-country"
                        placeholder="Selecciona el país"
                        {...params}
                        required
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password' // disable autocomplete and autofill
                        }}
                        sx={{
                          input: {
                            padding: '0 !important',
                            paddingLeft: '8px !important',
                            fontFamily: 'Sofia Pro',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px'
                          },
                          '.MuiOutlinedInput-root': { borderRadius: '8px' },
                          fieldset: {
                            border: errors.country_student
                              ? '1px solid red !important'
                              : '1px solid black !important'
                          }
                        }}
                      />
                    )}
                    popupIcon={
                      <IconArrowDown
                        fill={errors.country_student ? 'red' : '#313131'}
                      />
                    }
                    sx={{
                      '.css-1ldwfxf-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment ':
                        { right: '15px' },
                      '.MuiAutocomplete-popupIndicator[title=Open]': {
                        marginTop: '7px',
                        marginRight: '7px'
                      },
                      '.MuiAutocomplete-popupIndicator[title=Open]:hover': {backgroundColor: 'transparent'},
                      '.MuiAutocomplete-popupIndicator[title=Close]': {
                        marginTop: '-7px',
                        marginRight: '7px'
                      },
                      '.MuiAutocomplete-popupIndicator[title=Close]:hover': {backgroundColor: 'transparent'},
                      '.MuiAutocomplete-clearIndicator': { marginRight: '7px' },
                      '.MuiAutocomplete-clearIndicator:hover': {backgroundColor: 'transparent'},
                      '.MuiAutocomplete-endAdornment': {
                        height: '28px',
                        display: 'flex',
                        alignItems: 'center'
                      },
                      '.Mui-disabled': {'.MuiAutocomplete-endAdornment': {svg: { path: { fill: '#bcbcbc !important' } }}}
                    }}
                  />
                  {errors.country_student && (
                    <RenderError error={errors.country_student} />
                  )}
                </div>
              </>
            )}
          </>
          <>
            <Typography bold type="paragraph4" variation="mobile">
              Correo personal
            </Typography>
            {isDetail ? (
              <Typography type="paragraph4" variation="mobile">
                {values?.personal_email || '-'}
              </Typography>
            ) : (
              <Input
                value={values?.personal_email}
                onChange={handleChange}
                placeholder="Ingresa el mail personal"
                name="personal_email"
                isError={errors?.personal_email}
                hideErrorText={true}
                required
                error={errors?.personal_email ? errors?.personal_email : ''}
              />
            )}
          </>
        </div>
        <div className="detail-edit-students__student-information">
          <>
            <Typography bold type="paragraph4" variation="mobile">
              Apellidos{!isDetail && '*'}
            </Typography>
            {isDetail ? (
              <Typography type="paragraph4" variation="mobile">
                {values?.last_name || '-'}
              </Typography>
            ) : (
              <Input
                value={values?.last_name}
                onChange={handleChange}
                placeholder="Ingresa los apellidos del estudiante"
                name="last_name"
                required
                isError={errors?.last_name}
                hideErrorText={true}
                error={errors?.last_name ? errors?.last_name : ''}
              />
            )}
          </>
          <>
            <Typography bold type="paragraph4" variation="mobile">
              Nombre del colegio
            </Typography>
            {isDetail ? (
              <Typography type="paragraph4" variation="mobile">
                {values?.school_name || '-'}
              </Typography>
            ) : (
              <Input
                value={values?.school_name}
                onChange={handleChange}
                placeholder="Ingresa el nombre del colegio"
                name="school_name"
                required
                error={
                  errors?.school_name && touched?.school_name
                    ? errors?.school_name
                    : ''
                }
              />
            )}
          </>
          <>
            <Typography bold type="paragraph4" variation="mobile">
              Tutor/Padre/Madre
            </Typography>
            {isDetail ? (
              <Typography type="paragraph4" variation="mobile">
                {selectedGuardian?.first_name ? (
                  <Link
                    className="room-detail-header-v2__button-back"
                    to={`/dashboard/guardians?id=${selectedGuardian?.id}`}
                  >
                    {selectedGuardian?.first_name} {selectedGuardian?.last_name}
                  </Link>
                ) : (
                  '-'
                )}
              </Typography>
            ) : (
              <>
                <Autocomplete
                  className="detail-edit-students__student-information--guardian-dropdown"
                  fullWidth
                  id="guardian"
                  options={guardians}
                  autoHighlight
                  value={selectedGuardian}
                  onChange={handleGuardianChange}
                  getOptionLabel={option =>
                    `${option?.first_name} ${option?.last_name} (${option?.id})`
                  }
                  renderOption={(props, option) => (
                    <Box key={option?.id} component="li" {...props}>
                      {option?.first_name} {option?.last_name}&nbsp;&nbsp;
                      <strong>({option?.id})</strong>
                    </Box>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      className="textfield-student-guardian"
                      placeholder="Seleccionar tutor"
                      onChange={e => {
                        const val: any = e.target.value;
                        let filter: any = { full_name: val };
                        if (!isNaN(val)) {
                          filter = { id: val };
                        }
                        debouncedCallback(filter);
                      }}
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                      }}
                      sx={{
                        input: {
                          padding: '0 !important',
                          paddingLeft: '8px !important',
                          fontFamily: 'Sofia Pro',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '14px'
                        },
                        '.MuiOutlinedInput-root': { borderRadius: '8px' },
                        fieldset: {
                          border: errors.guardian
                            ? '1px solid red !important'
                            : '1px solid black !important'
                        }
                      }}
                    />
                  )}
                  popupIcon={<IconArrowDown />}
                  sx={{
                    '.css-1ldwfxf-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment ':
                      { right: '15px' },
                    '.MuiAutocomplete-popupIndicator[title=Open]': {
                      marginTop: '7px',
                      marginRight: '7px'
                    },
                    '.MuiAutocomplete-popupIndicator[title=Open]:hover': {backgroundColor: 'transparent'},
                    '.MuiAutocomplete-popupIndicator[title=Close]': {
                      marginTop: '-7px',
                      marginRight: '7px'
                    },
                    '.MuiAutocomplete-popupIndicator[title=Close]:hover': {backgroundColor: 'transparent'},
                    '.MuiAutocomplete-clearIndicator': { marginRight: '7px' },
                    '.MuiAutocomplete-clearIndicator:hover': {backgroundColor: 'transparent'},
                    '.MuiAutocomplete-endAdornment': {
                      height: '28px',
                      display: 'flex',
                      alignItems: 'center'
                    },
                    '.Mui-disabled': {'.MuiAutocomplete-endAdornment': {svg: { path: { fill: '#bcbcbc !important' } }}}
                  }}
                />
                {errors.guardian && touched.guardian && (
                  <RenderError error={errors.guardian} />
                )}
              </>
            )}
          </>
          <>
            <Typography bold type="paragraph4" variation="mobile">
              Numero de documento
            </Typography>
            {isDetail ? (
              <Typography type="paragraph4" variation="mobile">
                {values?.doc_number || '-'}
              </Typography>
            ) : (
              <Input
                value={values?.doc_number}
                onChange={handleChange}
                placeholder="Ingresa el número de documento"
                name="doc_number"
                required
                error={
                  errors?.doc_number && touched?.doc_number
                    ? errors?.doc_number
                    : ''
                }
              />
            )}
          </>
          <>
            <Typography bold type="paragraph4" variation="mobile">
              Ciudad
            </Typography>
            {isDetail ? (
              <Typography type="paragraph4" variation="mobile">
                {values?.city || '-'}
              </Typography>
            ) : (
              <Input
                value={values?.city}
                onChange={handleChange}
                placeholder="Ingresa la ciudad de residencia"
                name="city"
                required
                error={errors?.city && touched?.city ? errors?.city : ''}
              />
            )}
          </>
          <>
            <Typography bold type="paragraph4" variation="mobile">
              Teléfono
            </Typography>
            {isDetail ? (
              <Typography type="paragraph4" variation="mobile">
                {values?.phone_number || '-'}
              </Typography>
            ) : (
              <Input
                value={values?.phone_number}
                onChange={handleChange}
                placeholder="Ingresa el teléfono"
                name="phone_number"
                required
                error={
                  errors?.phone_number && touched?.phone_number
                    ? errors?.phone_number
                    : ''
                }
              />
            )}
          </>
        </div>
      </div>
    </>
  );
};
