import moment from 'moment';
import { AxiosError } from 'axios';
import { useEffect, useState, CSSProperties } from 'react';
import { useParams } from 'react-router-dom';

import {
  getStudentEvaluation,
  postCreateBulkStudentEvaluation
} from '../../../../../services/evaluations';
import colors from '../../../../../components/atoms/Colors';
import Typography from '../../../../../components/atoms/Typography/Typography';
import {
  IEvaluationStudent,
  IRoomDetailSimplified
} from '../../../../../interfaces/room.interface';
import TableEvaluationStudentV2 from '../../../../../components/organism/TableEvaluationStudent/TableEvaluationStudentV2';

interface Props {
  roomInfo: IRoomDetailSimplified;
  refetch: () => void;
}

const EvaluationStudent: React.FC<Props> = ({ roomInfo }) => {
  const { roomId } = useParams();
  const startDateEvaluation = moment(roomInfo.start_date_evaluation).format(
    'D MMMM'
  );
  const endDateEvaluation = moment(roomInfo.end_date_evaluation).format(
    'D MMMM YYYY'
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataEvaluationStudent, setDataEvaluationStudent] = useState<
    IEvaluationStudent[]
  >([]);

  const divStyles: CSSProperties = {
    display: 'flex',
    margin: '20px 0 50px 15px',
    alignItems: 'center',
    backgroundColor: colors.gris02,
    width: '380px',
    padding: '10px',
    borderRadius: '8px',
    justifyContent: 'center'
  };

  useEffect(() => {
    getEvaluationStudent(Number(roomId));
  }, []);

  const getEvaluationStudent = async (roomId: number) => {
    setIsLoading(true);
    try {
      const { data } = await getStudentEvaluation(roomId);
      if (data?.length === 0) {
        const create = [];
        for (let i = 0; i < roomInfo?.students.length; i++) {
          const studentId = roomInfo?.students[i]?.id;
          const studentOK =
            roomInfo?.students[i]?.enrolment_status !== 'abandoned';
          if (studentId && studentOK) {
            for (let category = 1; category <= 6; category++) {
              create.push({
                room: roomInfo.id,
                student: studentId,
                category: category
              });
            }
          }
        }
        handleSave(create);
      }
      setDataEvaluationStudent(data);
      setIsLoading(false);
    } catch (err) {
      const error: AxiosError = err as AxiosError;
      if (error) {
        console.log(error.response?.data);
        setIsLoading(false);
      }
    }
  };

  const handleSave = async (payload: any) => {
    await postCreateBulkStudentEvaluation(roomInfo.id, payload);
  };

  return (
    <>
      <div style={divStyles}>
        <Typography type="paragraph2" variation="mobile" bold>
          Evaluar entre el {startDateEvaluation} hasta el {endDateEvaluation}
        </Typography>
      </div>
      <TableEvaluationStudentV2
        roomInfo={roomInfo}
        dataEvaluationStudent={dataEvaluationStudent}
        startDateEvaluation={roomInfo.start_date_evaluation}
        endDateEvaluation={roomInfo.end_date_evaluation}
      />
    </>
  );
};

export default EvaluationStudent;
