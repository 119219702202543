/* eslint-disable complexity */
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import './certificateSending.scss';
import colors from '../../atoms/Colors';
import { useModal } from '../../../hooks/useModal';
import IconAlert from '../../atoms/icons/IconAlert';
import ButtonLineal from '../../molecules/ButtonLineal';
import ButtonFilled from '../../molecules/ButtonFilled';
import ButtonOutline from '../../molecules/ButtonOutline';
import ScreenLoader from '../../ScreenLoader/ScreenLoader';
import Typography from '../../atoms/Typography/Typography';
import ModalMessageAlert from '../../molecules/ModalMessageAlert';
import certificateSend from '../../../assets/img/certificateSend.svg';
import { IRoom, IStudent } from '../../../interfaces/group.interface';
import IconAlertSuccessNew from '../../atoms/icons/IconAlertSuccessNew';
import {
  postDriveCetificates,
  postSendCetificate
} from '../../../services/groups';

interface Props {
  students: IStudent[];
  rooms: IRoom[];
  course: any;
  hasSavedCertificate: boolean;
  checkedStudents: any[];
  setCheckedStudents: React.Dispatch<React.SetStateAction<any[]>>;
  initialValues: any;
  selectedBackground: any;
  getGroupCertificates: any;
  linkDrive: string;
}

const CertificateSending: React.FC<Props> = ({
  students,
  rooms,
  course,
  hasSavedCertificate,
  checkedStudents,
  setCheckedStudents,
  initialValues,
  selectedBackground,
  getGroupCertificates,
  linkDrive
}) => {
  const { groupId } = useParams();
  const [isLoadingSendCertificate, setIsLoadingSendCertificate] =
    useState(false);
  const [isErrorSendCertificate, setIsErrorSendCertificate] = useState(false);
  const [isLoadingDriveCertificates, setIsLoadingDriveCertificates] =
    useState(false);
  const [isErrorDriveCertificates, setIsErrorDriveCertificates] =
    useState(false);

  const {
    openModal: openModalSendCertificate,
    closeModal: closeModalSendCertificate,
    isOpenModal: isOpenModalSendCertificate
  } = useModal();

  const {
    openModal: openModalDriveCertificates,
    closeModal: closeModalDriveCertificates,
    isOpenModal: isOpenModalDriveCertificates
  } = useModal();

  const {
    openModal: openModalConfirmSendCertificate,
    closeModal: closeModalConfirmSendCertificate,
    isOpenModal: isOpenModalConfirmSendCertificate
  } = useModal();

  const arrayStudentByRooms = rooms.map(room =>
    students.filter(student => student.room.id === room.id)
  );

  const sendCertificate = async (params: any) => {
    try {
      setIsLoadingSendCertificate(true);
      const response = await postSendCetificate(params);
      if (response?.status === 200) {
        setIsLoadingSendCertificate(false);
        toast.success('¡Se envio los certificados correctamente!');
      } else {
        setIsErrorSendCertificate(true);
        toast.error('No se pudo enviar los certificados');
      }
    } catch (error) {
      setIsErrorSendCertificate(true);
      setIsLoadingSendCertificate(false);
      toast.error('Ha ocurrido un error al enviar los certificados');
    }
  };

  const generateDriveCertificate = async (params: any) => {
    try {
      setIsLoadingDriveCertificates(true);
      const response = await postDriveCetificates(params);
      console.log('response', response);
      console.log('rooms', rooms);
      if (response?.status === 200) {
        setIsLoadingDriveCertificates(false);
        toast.success('¡Se generó drive con los certificados correctamente!');
        getGroupCertificates();
      } else {
        setIsErrorDriveCertificates(true);
        toast.error('No se pudo generar drive con los certificados');
      }
    } catch (error) {
      setIsErrorDriveCertificates(true);
      setIsLoadingDriveCertificates(false);
      toast.error('Ha ocurrido un error al generar drive con los certificados');
    }
  };

  const handleOpcionChangeByStudent = (idx: number, student: any) => {
    const isChecked = checkedStudents.some(s => s.id === student.id);
    if (isChecked) {
      setCheckedStudents(checkedStudents.filter(s => s.id !== student.id));
    } else {
      setCheckedStudents([...checkedStudents, student]);
    }
  };

  const handleOpcionChangeByRoom = (idx: number, studentsByRoom: any) => {
    const isChecked =
      studentsByRoom.length ===
      checkedStudents.filter(s => s.room.id === studentsByRoom[0].room.id)
        .length;

    if (isChecked) {
      setCheckedStudents(
        checkedStudents.filter(s => s.room.id !== studentsByRoom[0].room.id)
      );
    } else {
      const historyStudents = checkedStudents
        ? checkedStudents.filter(s => s.room.id !== studentsByRoom[0].room.id)
        : [];
      setCheckedStudents([
        ...historyStudents,
        ...arrayStudentByRooms[idx].filter(
          s => s.room.id === studentsByRoom[0].room.id
        )
      ]);
    }
  };
  const handleCheckAllStudents = () => {
    const isChecked = students.length === checkedStudents.length;
    isChecked ? setCheckedStudents([]) : setCheckedStudents([...students]);
  };

  const handleClickSendCertificate = () => {
    if (checkedStudents.length > 0 && selectedBackground?.url) {
      const originalElement = document.getElementById('certificate-to-print');

      if (originalElement) {
        const elementClone = originalElement.cloneNode(true) as HTMLElement;

        // Use XMLSerializer to convert the cloned element to a string
        const serializer = new XMLSerializer();
        const certificateHTMLToString =
          serializer.serializeToString(elementClone);

        const studentsCheck = {
          students: checkedStudents.map(student => student.id),
          certificate_html: certificateHTMLToString,
          course: course
        };
        sendCertificate(studentsCheck);
        closeModalConfirmSendCertificate();
        openModalSendCertificate();
      }
    }
  };

  const handleClickReSendCertificate = () => {
    if (!isErrorSendCertificate) {
      setCheckedStudents([]);
    }
    closeModalSendCertificate();
  };

  const CertificateSendingColumns = ({
    rooms,
    startSlice,
    endSlice,
    checkedStudents,
    handleOpcionChangeByStudent,
    idx
  }: any) => (
    <div className="certificate-sending__columns">
      {rooms
        .slice(startSlice, endSlice)
        .map((studentsByRoom: any, index: number) => (
          <div className="certificate-sending__item" key={index}>
            <input
              type="checkbox"
              className="certificate-sending__input"
              checked={checkedStudents.some(
                (s: any) => s.id === studentsByRoom.id
              )}
              onChange={() => handleOpcionChangeByStudent(idx, studentsByRoom)}
            />
            <label>{studentsByRoom.full_name}</label>
          </div>
        ))}
    </div>
  );

  const handleGenerateLinkDriveCertificatePDF = async () => {
    if (checkedStudents.length > 0 && selectedBackground?.url) {
      setIsLoadingDriveCertificates(true);

      const originalElement = document.getElementById('certificate-to-print');

      if (originalElement) {
        const elementClone = originalElement.cloneNode(true) as HTMLElement;

        // Use XMLSerializer to convert the cloned element to a string
        const serializer = new XMLSerializer();
        const certificateHTMLToString =
          serializer.serializeToString(elementClone);

        const studentsCheck = {
          students: checkedStudents.map(student => student.id),
          certificate_html: certificateHTMLToString,
          group: Number(groupId)
        };
        generateDriveCertificate(studentsCheck);
        openModalDriveCertificates();
        setCheckedStudents([]);
      }
    }
  };

  if (!rooms || !students) return <ScreenLoader />;

  return (
    <>
      <div className="certificate-sending">
        <section className="certificate-sending__container-information">
          <Typography
            type="paragraph1"
            variation="desktop"
            bold
            className="certificate-sending__title"
          >
            Envío
          </Typography>
          <Typography type="paragraph2" variation="mobile">
            Personaliza el envío de los certificados por salones y por
            estudiantes:
          </Typography>
          {rooms && rooms.length > 1 && (
            <section className="certificate-sending__list-container-room">
              <div>
                <input
                  type="checkbox"
                  className="certificate-sending__input"
                  checked={students.length === checkedStudents.length}
                  onChange={() => handleCheckAllStudents()}
                />
                <label>Seleccionar todos</label>
              </div>
            </section>
          )}
          <Typography
            className="certificate-sending__subtitle-students"
            type="paragraph2"
            variation="mobile"
            bold
          >
            Estudiantes
          </Typography>
          <section className="certificate-sending__list-container-room">
            {arrayStudentByRooms.map((room, roomIdx) => {
              const numberOfStudents = room.length;
              const numberOfColumns = 3;
              const studentsPerColumn = Math.ceil(
                numberOfStudents / numberOfColumns
              );

              return (
                <div
                  key={`certificate-${roomIdx}`}
                  className="certificate-sending__container-room"
                >
                  <Typography
                    className="certificate-sending__number-rooms"
                    type="paragraph2"
                    bold
                  >
                    <input
                      type="checkbox"
                      className="certificate-sending__input"
                      checked={
                        numberOfStudents ===
                        checkedStudents.filter(
                          s => s.room.id === room[0].room.id
                        ).length
                      }
                      onChange={() => handleOpcionChangeByRoom(roomIdx, room)}
                    />
                    Salón 0{roomIdx + 1}
                  </Typography>
                  <section className="certificate-sending__list">
                    {Array.from(
                      { length: numberOfColumns },
                      (_, columnIndex) => {
                        const startSlice = columnIndex * studentsPerColumn;
                        const endSlice = Math.min(
                          startSlice + studentsPerColumn,
                          numberOfStudents
                        );
                        const columnStudents = room.slice(startSlice, endSlice);

                        return (
                          <CertificateSendingColumns
                            key={columnIndex}
                            rooms={columnStudents}
                            checkedStudents={checkedStudents}
                            handleOpcionChangeByStudent={
                              handleOpcionChangeByStudent
                            }
                            idx={roomIdx}
                          />
                        );
                      }
                    )}
                  </section>
                </div>
              );
            })}
          </section>
          <section className="certificate-sending__container-buttons">
            <ButtonFilled
              size="default"
              width="260px"
              onClick={() => handleGenerateLinkDriveCertificatePDF()}
              disabled={
                checkedStudents.length > 0 && !isLoadingDriveCertificates
                  ? false
                  : true
              }
            >
              Exportar a drive
            </ButtonFilled>
            <ButtonFilled
              type="submit"
              size="default"
              width="260px"
              onClick={() => openModalConfirmSendCertificate()}
              disabled={
                checkedStudents.length > 0 &&
                hasSavedCertificate &&
                initialValues?.backgroundUrl &&
                selectedBackground?.url
                  ? false
                  : true
              }
            >
              Enviar por correo
            </ButtonFilled>
          </section>
        </section>
        <section className="certificate-sending__container-information">
          <Typography
            type="paragraph1"
            variation="desktop"
            bold
            className="certificate-sending__title"
          >
            Carpeta de Drive
          </Typography>
          <Typography type="paragraph2 mb_18" variation="mobile">
            En esta carpeta encontrarás todos los de certificados que se hayan
            creado y exportado a Drive para este salón.
          </Typography>
          <ButtonLineal
            hasPadding={false}
            to={linkDrive}
            external
            customStyles={{ marginTop: 36 }}
          >
            Ir a la carpeta de Drive
          </ButtonLineal>
        </section>
      </div>
      <ModalMessageAlert
        width="sm"
        iconAlert={<IconAlert fill={colors.colorAlertRed} />}
        title={
          <Typography bold type="paragraph1" variation="desktop">
            ¿Deseas confirmar el envío?
          </Typography>
        }
        message="Una vez que hayas confirmado el envío, el certificado y los destinatarios no podrán ser modificados ni detenidos."
        isOpenModal={isOpenModalConfirmSendCertificate}
        openModal={openModalConfirmSendCertificate}
        closeModal={closeModalConfirmSendCertificate}
        align="center"
        button1={
          <ButtonOutline
            size="default"
            onClick={closeModalConfirmSendCertificate}
          >
            Salir
          </ButtonOutline>
        }
        button2={
          <ButtonFilled
            size="default"
            onClick={() => handleClickSendCertificate()}
          >
            Confirmar y enviar
          </ButtonFilled>
        }
      />
      <ModalMessageAlert
        width="sm"
        iconAlert={
          isLoadingDriveCertificates ? (
            <ScreenLoader
              fullWidth={false}
              text={false}
              className="certificate-sending__loading"
            />
          ) : (
            <IconAlertSuccessNew className="certificate-sending__icon-success" />
          )
        }
        title={
          <Typography bold type="paragraph1" variation="desktop">
            {isLoadingDriveCertificates
              ? 'Guardando certificados en la carpeta de Drive'
              : isErrorDriveCertificates
                ? 'Ocurrió un error al crear la carpeta con los certificados'
                : 'Certificados creados y guardados en Drive'}
          </Typography>
        }
        body={
          isLoadingDriveCertificates ? (
            'Espere un momento mientras se crea la carpeta con los nuevos certificados.'
          ) : isErrorDriveCertificates ? (
            'Vuelve a intentarlo y si el problema persiste, consultar con tecnología.'
          ) : (
            <>
              <Typography type="paragraph2" variation="mobile" align="center">
                Puedes encontrar los certificados de los <br /> estudiantes
                seleccionados en la carpeta.
              </Typography>
              <Typography
                bold
                type="paragraph2"
                variation="mobile"
                align="center"
                customStyles={{ marginTop: 16 }}
              >
                Cada vez que crees nuevos certificados, los <br /> mismos
                quedarán guardados en esa carpeta.
              </Typography>
            </>
          )
        }
        isOpenModal={isOpenModalDriveCertificates}
        openModal={openModalDriveCertificates}
        closeModal={closeModalDriveCertificates}
        align="center"
        button1={
          <ButtonFilled
            size="default"
            fullWidth
            onClick={closeModalDriveCertificates}
            disabled={isLoadingDriveCertificates ? true : false}
          >
            Cerrar
          </ButtonFilled>
        }
        button2={
          <ButtonLineal
            size="default"
            fullWidth
            customStyles={{ marginTop: 16 }}
            to={linkDrive}
            external
            disabled={isLoadingDriveCertificates || !linkDrive ? true : false}
          >
            Ir a la carpeta de Drive
          </ButtonLineal>
        }
      />
      <ModalMessageAlert
        width="sm"
        iconAlert={
          isLoadingSendCertificate ? (
            <ScreenLoader
              fullWidth={false}
              text={false}
              className="certificate-sending__loading"
            />
          ) : (
            <img
              className="certificate-sending__image-send-modal"
              src={certificateSend}
              alt="Certificate Send"
            />
          )
        }
        title={
          <Typography bold type="paragraph1" variation="desktop">
            {isLoadingSendCertificate
              ? 'Enviando...'
              : isErrorSendCertificate
                ? 'Ocurrió un error al enviar los certificados'
                : '¡Certificados enviados!'}
          </Typography>
        }
        message={
          isLoadingSendCertificate
            ? 'Espere un momento mientras se envian los certificados'
            : isErrorSendCertificate
              ? 'No pudimos enviar los certificados a los alumnos seleccionados, por favor vuelve a intentarlo o contáctate con tecnología'
              : 'Los certificados han sido enviados con éxito a los estudiantes seleccionados.'
        }
        isOpenModal={isOpenModalSendCertificate}
        openModal={openModalSendCertificate}
        closeModal={closeModalSendCertificate}
        align="center"
        button1={
          <ButtonFilled
            size="default"
            onClick={() => handleClickReSendCertificate()}
            disabled={isLoadingSendCertificate ? true : false}
          >
            Cerrar
          </ButtonFilled>
        }
      />
    </>
  );
};

export default CertificateSending;
