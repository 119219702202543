/* eslint-disable max-lines */
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { useState, useEffect } from 'react';
import {
  Table,
  Paper,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  IconButton,
  FormControl,
  TableContainer,
  SelectChangeEvent
} from '@material-ui/core';
import { Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import Popover from '@mui/material/Popover';
import VisibilityIcon from '@mui/icons-material/Visibility';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  AttendanceType,
  getListAbsenceReasons,
  patchAttendanceMutation,
  postCreateUniqueAttendance
} from '../../../services/attendance';
import Modal from '../../atoms/Modal';
import './tableAttendanceStudent.scss';
import colors from '../../atoms/Colors';
import { LazySelect } from './LazySelect';
import {
  IStudent,
  IDateSession,
  IStudentRecord,
  IRoomDetailSimplified
} from '../../../interfaces/room.interface';
import {
  ADMIN,
  TEACHER_AUXILIARY,
  TEACHER_CTC
} from '../../../constants/usersType.constants';
import { RootState } from '../../../store/store';
import IconInfo from '../../atoms/icons/IconInfo';
import Button from '../../molecules/Button/Button';
import { useModal } from '../../../hooks/useModal';
import IconAlert from '../../atoms/icons/IconAlert';
import IconAddSymbol from '../../atoms/icons/IconAddSymbol';
import IconAddSession from '../../atoms/icons/IconAddSession';
import NewTypography from '../../atoms/Typography/Typography';
import IconCloseSession from '../../atoms/icons/IconCloseSession';
import PERMISSIONS from '../../../constants/permission.constants';
import ModalJustification from '../../molecules/ModalJustification';
import { addSesion, disableSesion } from '../../../services/rooms';
import ButtonFilled from '../../molecules/ButtonFilled/ButtonFilled';
import ButtonLineal from '../../molecules/ButtonLineal/ButtonLineal';
import Paragraph from '../../../components/common/Paragraph/Paragraph';
import { patchChangeEnrollmentState } from '../../../services/students';
import ButtonOutline from '../../molecules/ButtonOutline/ButtonOutline';
import IconEditPencil from '../../../components/atoms/icons/IconEditPencil';
import IconOutlinedInformation from '../../atoms/icons/IconOutlinedInformation';
import useInactiveStudentReasons from '../../../hooks/useInactiveStudentReasons';
import ModalMessageAlert from '../../molecules/ModalMessageAlert/ModalMessageAlert';
import ModalInactiveStudent from '../../molecules/ModalInactiveStudent/ModalInactiveStudent';
import ModalInactiveStudentsReasons from '../../../components/organism/ModalInactiveStudentsReasons/ModalInactiveStudentsReasons';

interface Props {
  roomInfo: IRoomDetailSimplified;
  studentRecords: IStudentRecord[] | undefined;
  refetch: () => void;
  refetchAttendanceDetails: () => void;
  setOpenModal: any;
  setValueDateId: any;
  setValueDate: any;
}

const TableAttendanceStudentV2: React.FC<Props> = ({
  roomInfo,
  setOpenModal,
  setValueDate,
  studentRecords,
  setValueDateId,
  refetch,
  refetchAttendanceDetails
}) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const today = moment();
  const isB2B = roomInfo.business_type === 'B2B';
  const {
    openModal: openModalDeleteSesion,
    closeModal: closeModalDeleteSesion,
    isOpenModal: isOpenModalDeleteSesion
  } = useModal();
  const {
    openModal: openModalAddSesion,
    closeModal: closeModalAddSesion,
    isOpenModal: isOpenModalAddSesion
  } = useModal();
  const {
    openModal: openModalAddJustification,
    closeModal: closeModalAddJustification,
    isOpenModal: isOpenModalAddJustification
  } = useModal();
  const {
    openModal: openModalDetailJustification,
    closeModal: closeModalDetailJustification,
    isOpenModal: isOpenModalDetailJustification
  } = useModal();
  const [selectSesion, setSelectSesion] = useState<any>();
  const [reasonOptions, setReasonOptions] = useState<any>();
  const [studentName, setStudentName] = useState<any>();
  const [studentState, setStudentState] = useState<any>();
  const [studentId, setStudentId] = useState<any>();
  const [currentSessionId, setCurrentSessionId] = useState<any>();
  const [currentStudent, setCurrentStudent] = useState<any>();
  const [addSessionInProgress, setAddSessionInProgress] = useState<any>(false);
  const [payloadInactiveStudent, setPayloadInactiveStudent] = useState<any>();
  const [inactiveStudentReason, setInactiveStudentReason] = useState<any>();
  const [listReasonAbsence, setListReasonAbsence] = useState<any>();
  const [reasonAbsence, setReasonAbsence] = useState({ id: 0, name: '' });
  const [typeModalJustification, setTypeModalJustification] = useState<
    'add' | 'edit'
  >('add');
  const isDisabledMenuItemAttendanceStudent =
    !user?.permission[PERMISSIONS.attendanceList] ||
    user?.permission?.name === TEACHER_AUXILIARY;

  const statusClasses: { [key: string]: string } = {
    0: 'table-attendance-student__null',
    1: 'table-attendance-student__attended',
    2: 'table-attendance-student__delay',
    3: 'table-attendance-student__absence',
    4: 'table-attendance-student__justified',
    5: 'table-attendance-student__recovered',
    6: 'table-attendance-student__material',
    7: 'table-attendance-teacher__justified',
    default: 'table-attendance-student__disable'
  };

  const statusColors: { [key: string]: string } = {
    0: colors.gris05,
    1: colors.statusAttendedText,
    2: colors.statusDelayText,
    3: colors.statusAbsenceText,
    4: colors.statusJustifiedText,
    5: colors.statusRecoveredText,
    6: colors.statusMaterialText,
    7: colors.statusJustifiedText,
    default: colors.gris03
  };

  const canAddJustificationReason = (status: any) => {
    // validate if the attendance is Absence or Justified
    if (status === 3 || status === 4) {
      return true;
    }
    return false;
  };

  const getStatusColor = (status: any) => {
    const colorMap: any = {
      done: colors.statusAttendedText,
      risk: colors.statusDelayText,
      default: colors.statusAbsenceText
    };
    return colorMap[status] || colorMap.default;
  };

  const getStatusLabel = (status: any) => {
    const labelMap: any = {
      done: 'Activo',
      risk: 'Riesgo',
      inactive: 'Inactivo',
      default: 'Abandono'
    };
    return labelMap[status] || labelMap.default;
  };

  const listReasonsAbsence = async () => {
    try {
      const response = await getListAbsenceReasons();
      setListReasonAbsence(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddNewSesion = async (roomId: number) => {
    try {
      setAddSessionInProgress(true);
      const response = await addSesion(roomId);
      if (response?.status === 201) {
        setAddSessionInProgress(false);
        toast.success('¡Se agregó la sesión correctamente!');
        refetch();
      } else {
        setAddSessionInProgress(false);
        toast.error('No se pudo agregar la sesión');
      }
    } catch (error) {
      setAddSessionInProgress(false);
      toast.error('Ha ocurrido un error al crear la sesión');
    }
  };

  const handleDeleteSesion = async (
    room_id: number,
    session_id: number,
    state_session: boolean
  ) => {
    try {
      const response = await disableSesion({
        room_id,
        session_id,
        state_session
      });
      if (response?.status === 200) {
        toast.success(
          `¡Se ${state_session ? 'habilito' : 'elimino'} la sesión correctamente!`
        );
        refetch();
      } else {
        toast.error(
          `No se pudo ${state_session ? 'habilitar' : 'eliminar'} la sesión`
        );
      }
    } catch (error) {
      toast.error(
        `Ha ocurrido un error al ${state_session ? 'habilitar' : 'eliminar'} la sesión`
      );
    }
  };

  const createUniqueAttendance = async (attendance: any) => {
    try {
      const data = await postCreateUniqueAttendance(attendance);
      if (data) {
        toast.success('Asistencia tomada correctamente!');
      } else {
        toast.error('Ha ocurrido un error al tomar la asistencia.');
      }
    } catch (error) {
      toast.error('Ha ocurrido un error al tomar la asistencia.');
    }
  };

  const updateAttendanceMutation = async (attendance: any) => {
    try {
      const data = await patchAttendanceMutation(attendance);
      if (data) {
        toast.success('Asistencia actualizada correctamente!');
      } else {
        toast.error('Ha ocurrido un error al actualizar la asistencia.');
      }
    } catch (error) {
      toast.error('Ha ocurrido un error al actualizar la asistencia.');
    }
  };

  const handleSubmitUniqueAttendance = async (
    event: any,
    numberOfSession: any,
    roomId: any,
    student: any
  ) => {
    const newAttendance = {
      number_of_session: numberOfSession,
      status: event.target.value,
      object_id: student,
      object_type: AttendanceType.student,
      room: roomId
    };

    await createUniqueAttendance({ ...newAttendance });
    refetchAttendanceDetails();
  };

  const handleUpdateStateAttendance = async (event: any, attendance: any) => {
    await updateAttendanceMutation({
      id: attendance.id,
      status: event.target.value
    });
    refetchAttendanceDetails();
  };

  const deleteSesion = async (sesion: any) => {
    handleDeleteSesion(roomInfo?.id, sesion, false);
    closeModalDeleteSesion();
  };

  const habilitarSesion = async (sesion: any) => {
    handleDeleteSesion(roomInfo?.id, sesion, true);
    closeModalAddSesion();
  };

  const [credentialsAnchorEl, setCredentialAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [currentPopoverSelected, setCurrentPopoverSelected] = useState<
    string | null
  >(null);

  const handleCredentialsClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    setCurrentPopoverSelected(id);
    setCredentialAnchorEl(event.currentTarget);
  };

  const handleCredentialsClose = () => {
    setCurrentPopoverSelected(null);
    setCredentialAnchorEl(null);
  };

  const getActiveSessions = (date_sessions: IDateSession[]) => {
    let numberOfSessions = 0;
    date_sessions.forEach(session => {
      if (session.state) {
        numberOfSessions += 1;
      }
    });
    return numberOfSessions;
  };

  const {
    openModal: openModalAddTeacherEtario,
    closeModal: closeModalAddTeacherEtario,
    isOpenModal: isOpenModalAddTeacherEtario
  } = useModal();

  const {
    openModal: openModalActiveStudent,
    closeModal: closeModalActiveStudent,
    isOpenModal: isOpenModalActiveStudent
  } = useModal();

  const {
    openModal: openModalConfirmActive,
    closeModal: closeModalConfirmActive,
    isOpenModal: isOpenModalConfirmActive
  } = useModal();

  const handleConfirm = () => {
    closeModalActiveStudent();
    openModalConfirmActive();
  };

  const { data, isLoading } = useInactiveStudentReasons({shouldRefetch: false});

  useEffect(() => {
    setReasonOptions(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleOpenModalInactiveStudent = ({
    studentName,
    studentId
  }: {
    studentName: any;
    studentId: any;
  }) => {
    setStudentName(studentName);
    setStudentId(studentId);
    openModalAddTeacherEtario();
  };

  const handleActiveStudent = async () => {
    closeModalConfirmActive();

    const data = await patchChangeEnrollmentState(payloadInactiveStudent);
    if (data && data?.status === 200) {
      toast.success('Se activó el estudiante');
      refetchAttendanceDetails();
      refetch();
      return data;
    } else {
      toast.error('No se activó el estudiante');
    }
  };

  const generalStyles: any = {
    fontFamily: 'Sofia Pro',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 12,
    borderBottom: 'none',
    textAlign: 'center'
  };

  const menuItemData: any = [
    {
      value: 1,
      message: 'A',
      status: 'attended',
      minWidth: 105,
      addHR: true
    },
    {
      value: 2,
      message: 'T',
      status: 'delay',
      addHR: true
    },
    {
      value: 3,
      message: 'F',
      status: 'absence',
      addHR: true
    },
    {
      value: 4,
      message: 'J',
      status: 'justified',
      addHR: true
    },
    {
      value: 5,
      message: 'R',
      status: 'recovered',
      addHR: true,
      disabled: isDisabledMenuItemAttendanceStudent
    },
    {
      value: 6,
      message: 'MR',
      status: 'material',
      addHR: true,
      disabled: isDisabledMenuItemAttendanceStudent
    },
    {
      value: 7,
      message: 'N/A',
      status: 'N/A',
      disabled: isDisabledMenuItemAttendanceStudent
    }
  ];

  const RenderInactiveActions = ({ row }: { row: any }) => {
    return row.enrolment_status === 'inactive' ? (
      <button
        style={{
          border: '0px',
          backgroundColor: 'inherit',
          cursor: 'pointer'
        }}
        onClick={() => {
          const payload = {
            student_id: row.id,
            room_id: roomInfo?.id,
            state: 'done'
          };
          setStudentState(row.enrolment_status);
          setPayloadInactiveStudent(payload);
          setStudentName(row.full_name);
          setStudentId(row.id);
          setInactiveStudentReason(row.reason);
          openModalActiveStudent();
        }}
      >
        <IconInfo width="14" height="16" fill={'#D84949'} />
      </button>
    ) : user?.permission.name === ADMIN ? (
      <button
        style={{
          border: '0px',
          backgroundColor: 'inherit',
          cursor: 'pointer'
        }}
        onClick={() => {
          setInactiveStudentReason(null);
          setStudentId(row.id);
          setStudentState(row.enrolment_status);
          handleOpenModalInactiveStudent({
            studentName: row.full_name,
            studentId: row.id
          });
        }}
      >
        <IconEditPencil width="14" height="16" fill={'#313131'} />
      </button>
    ) : null;
  };

  const disabledSessionBox = ({
    session,
    attendanceForCurrentSession,
    row
  }: {
    session: any;
    attendanceForCurrentSession: any;
    row: any;
  }) => {
    return (
      !session.state ||
      (!user?.permission[PERMISSIONS.attendanceList] &&
        (today.isBefore(moment(session.start_date).format('YYYY-MM-DD')) ||
          attendanceForCurrentSession?.status === 5 ||
          attendanceForCurrentSession?.status === 6)) ||
      (user?.permission.name === TEACHER_CTC &&
        user.id !== session.teacher_id) ||
      (user?.permission.name === TEACHER_AUXILIARY &&
        today.isBefore(moment(session.start_date).format('YYYY-MM-DD'))) ||
      row.enrolment_status === 'inactive'
    );
  };

  const RenderButtonAddSession = ({roomInfo}: {
    roomInfo: IRoomDetailSimplified;
  }) => {
    const isButtonDisabled = roomInfo?.extra_session_number >= 5;

    const button = (
      <button
        style={{ width: '110px' }}
        disabled={isButtonDisabled || addSessionInProgress}
        className="table-attendance-student__new-section"
        onClick={() => handleAddNewSesion(roomInfo?.id)}
      >
        Añadir sesión{' '}
        {isButtonDisabled
          ? ''
          : `(${5 - Number(roomInfo?.extra_session_number)})`}
      </button>
    );

    return isButtonDisabled ? (
      <Tooltip
        classes={{ tooltip: 'custom-tooltip' }}
        PopperProps={{ style: { maxWidth: 150 } }}
        title="Ya se agregaron las sesiones máximas permitidas."
        arrow
        placement="top"
      >
        {button}
      </Tooltip>
    ) : (
      button
    );
  };

  const showModalDetailJustification = (
    attendanceForCurrentSession: any
  ): void => {
    openModalDetailJustification();
    setReasonAbsence(
      attendanceForCurrentSession?.absence_reason || { id: 0, name: '' }
    );
  };

  const showModalAddJustification = (
    typeModalAdmin: 'add' | 'edit',
    row: any,
    attendanceForCurrentSession: any
  ): void => {
    setTypeModalJustification(typeModalAdmin);
    setReasonAbsence(
      attendanceForCurrentSession?.absence_reason || { id: 0, name: '' }
    );
    openModalAddJustification();
    listReasonsAbsence();
    setCurrentStudent(row.full_name);
    setCurrentSessionId(attendanceForCurrentSession?.id);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 700,
          boxShadow: 'none',
          '&::-webkit-scrollbar': { height: 12 },
          '&::-webkit-scrollbar-track': { backgroundColor: colors.gris02 },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: colors.gris03,
            borderRadius: 2
          }
        }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          {/* Header */}
          <TableHead>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: colors.gris05,
                  left: 0,
                  background: '#fff',
                  minWidth: 120,
                  maxWidth: 120,
                  width: 120,
                  borderBottom: 'none',
                  pb: 0
                }}
                className="room-details-student-attendance-tables-sticky"
              ></TableCell>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  left: 152,
                  background: '#fff',
                  minWidth: 200,
                  maxWidth: 250,
                  width: 200,
                  borderBottom: 'none',
                  pb: 0
                }}
                className="room-details-student-attendance-tables-sticky"
              ></TableCell>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  color: colors.gris05,
                  left: 384,
                  background: '#fff',
                  minWidth: 120,
                  maxWidth: 120,
                  width: 120,
                  borderBottom: 'none',
                  pb: 0
                }}
                className="room-details-student-attendance-tables-sticky"
              ></TableCell>
              {roomInfo.date_session.map((session: IDateSession) => (
                <TableCell
                  key={session?.id}
                  component="td"
                  scope="row"
                  sx={{
                    color: '#757575',
                    minWidth: 102,
                    width: 102,
                    p: '12px 4px',
                    pb: 0,
                    borderBottom: 'none'
                  }}
                >
                  <Stack
                    /* direction="column" */
                    columnGap={1.5}
                    alignItems="center"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      fontFamily: 'Sofia Pro',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: 12,
                      justifyContent: 'center',
                      color: today.isAfter(
                        moment(session?.start_date).format('YYYY-MM-DD')
                      )
                        ? colors.gris03
                        : session.state
                          ? colors.gris05
                          : colors.gris02
                    }}
                  >
                    {moment(session?.start_date).format('D MMM YYYY')}
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  left: 0,
                  pt: 0,
                  background: '#fff',
                  minWidth: 120,
                  maxWidth: 120,
                  width: 120,
                  ...generalStyles
                }}
                className="room-details-student-attendance-tables-sticky"
              >
                Estado
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  pt: 0,
                  background: '#fff',
                  minWidth: 200,
                  maxWidth: 250,
                  width: 200,
                  ...generalStyles,
                  left: 152
                }}
                className="room-details-student-attendance-tables-sticky"
              >
                Estudiante
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  left: 384,
                  pt: 0,
                  background: '#fff',
                  minWidth: 120,
                  maxWidth: 200,
                  width: 120,
                  ...generalStyles
                }}
                className="room-details-student-attendance-tables-sticky"
              >
                Credenciales
              </TableCell>
              {roomInfo?.date_session.length > 0
                ? roomInfo?.date_session.map((session, index) => {
                    return (
                      <TableCell
                        key={session.session_number}
                        component="th"
                        scope="row"
                        align="center"
                        sx={{
                          pt: 0,
                          ...generalStyles,
                          color: today.isAfter(
                            moment(session?.start_date).format('YYYY-MM-DD')
                          )
                            ? colors.gris03
                            : session.state
                              ? colors.gris05
                              : colors.gris02
                        }}
                      >
                        Sesión {session.session_number}
                        <Button
                          className={`
													table-attendance-student__button-delete
													${session.state ? '' : 'table-attendance-student__sesion-disable-icon'}
													${
                            user?.permission[PERMISSIONS.attendanceList] &&
                            user?.permission?.name !== TEACHER_AUXILIARY
                              ? ''
                              : 'table-attendance-student__sesion-disable-icon-cursor'
                          }
												`}
                          onClick={
                            user?.permission[PERMISSIONS.attendanceList] &&
                            user?.permission?.name !== TEACHER_AUXILIARY
                              ? () => {
                                  setSelectSesion(session.id);
                                  session.state
                                    ? openModalDeleteSesion()
                                    : openModalAddSesion();
                                }
                              : () => {}
                          }
                        >
                          {session.state ? (
                            <IconCloseSession />
                          ) : (
                            <IconAddSession />
                          )}
                        </Button>
                      </TableCell>
                    );
                  })
                : null}
              {user?.permission[PERMISSIONS.attendanceList] &&
              user?.permission?.name !== TEACHER_AUXILIARY ? (
                <>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      pt: 0,
                      background: '#fff',
                      minWidth: 100,
                      maxWidth: 100,
                      width: 100,
                      textAlign: 'center',
                      borderBottom: 'none'
                    }}
                  >
                    <RenderButtonAddSession roomInfo={roomInfo} />
                  </TableCell>
                </>
              ) : null}
            </TableRow>
          </TableHead>

          {/* Body */}
          <TableBody>
            {roomInfo.students?.map((row: IStudent, index_row: number) => (
              <TableRow key={row.full_name}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ left: 5 }}
                  className="room-details-student-attendance-tables-sticky"
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'left'
                    }}
                  >
                    <Typography
                      sx={{
                        color: getStatusColor(row.enrolment_status),
                        background: colors.gris01,
                        fontFamily: 'Sofia Pro',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        borderRadius: 1,
                        p: '8px 8px 4px',
                        fontSize: 14,
                        gap: 4,
                        textAlign: 'center',
                        marginRight: '10px',
                        minWidth: '100px'
                      }}
                    >
                      {getStatusLabel(row.enrolment_status)}
                    </Typography>
                    {isB2B && <RenderInactiveActions row={row} />}
                  </div>
                </TableCell>
                {/* Student */}
                <TableCell
                  component="th"
                  scope="row"
                  className="room-details-student-attendance-tables-sticky"
                  sx={{ left: 152 }}
                >
                  <Stack direction="row" columnGap={1.5} alignItems="center">
                    <div
                      style={{
                        color:
                          row.enrolment_status === 'abandoned' ||
                          row.enrolment_status === 'inactive'
                            ? colors.gris03
                            : colors.gris05,
                        width: '100%',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        fontFamily: 'Sofia Pro',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: 14,
                        position: 'sticky',
                        textAlign: 'center'
                      }}
                    >
                      {row.full_name}
                    </div>
                  </Stack>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    textAlign: 'center',
                    left: 384
                  }}
                  className="room-details-student-attendance-tables-sticky"
                >
                  <IconButton
                    disabled={row.enrolment_status === 'inactive'}
                    onClick={e =>
                      handleCredentialsClick(e, 'credentials-popover-' + row.id)
                    }
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <Popover
                    id={'credentials-popover-' + row.id}
                    open={
                      currentPopoverSelected == 'credentials-popover-' + row.id
                    }
                    anchorEl={credentialsAnchorEl}
                    onClose={handleCredentialsClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                  >
                    <Typography className="room-details-credentials">
                      <div className="room-details-credentials-section-title">
                        Email
                      </div>
                      <Paragraph
                        text={row.email}
                        mailable={{ email: row.email }}
                        copyable
                      />
                    </Typography>
                    <Typography className="room-details-credentials">
                      <div className="room-details-credentials-section-title">
                        Contraseña Moodle
                      </div>
                      <Paragraph text={row.password} copyable hideable />
                    </Typography>
                    <Typography className="room-details-credentials">
                      <div className="room-details-credentials-section-title">
                        Contraseña Google
                      </div>
                      <Paragraph text={row.password_google} copyable hideable />
                    </Typography>
                  </Popover>
                </TableCell>
                {/* Attendances */}
                {roomInfo.date_session.map(
                  (session: IDateSession, index: number) => {
                    let studentAttendances = null;

                    if (studentRecords && studentRecords.length > 0) {
                      studentAttendances = studentRecords.find(
                        studentRecord => {
                          return studentRecord.student.id === row.id;
                        }
                      );
                    }

                    const attendanceForCurrentSession = studentAttendances
                      ? studentAttendances.attendances.find(
                          attendance =>
                            session.session_number ===
                            attendance.number_of_session
                        )
                      : null;

                    const isTodayBeforeStartDate = today.isBefore(
                      moment(session?.start_date).format('YYYY-MM-DD')
                    );
                    const attendanceStatus: number =
                      attendanceForCurrentSession?.status || 0;

                    const getAttendanceStatusClass = (): string => {
                      const isSessionDisabled =
                        !session.state ||
                        (isTodayBeforeStartDate &&
                          !user?.permission[PERMISSIONS.attendanceList]);

                      if (isSessionDisabled) {
                        return statusClasses.default;
                      }

                      return (
                        statusClasses[attendanceStatus] || statusClasses['0']
                      );
                    };

                    const getColor = (): string => {
                      const isDisabled =
                        !user?.permission[PERMISSIONS.attendanceList] &&
                        isTodayBeforeStartDate;

                      if (isDisabled) {
                        return colors.gris02;
                      }

                      if (
                        !session.state ||
                        (user?.permission.name === 'Teacher' &&
                          user.id !== session.teacher_id)
                      ) {
                        return 'transparent';
                      }

                      return (
                        statusColors[attendanceStatus] || statusColors.default
                      );
                    };
                    const cssClassAttendance = getAttendanceStatusClass();
                    const isPossibleAddJustification =
                      canAddJustificationReason(attendanceStatus);
                    const colorAttendance = getColor();
                    const isDisabledSessionBox = disabledSessionBox({
                      session,
                      attendanceForCurrentSession,
                      row
                    });
                    return (
                      <TableCell
                        component="th"
                        scope="row"
                        key={index}
                        sx={{
                          p: '0px 20px',
                          textAlign: 'center'
                        }}
                      >
                        <div style={{ display: 'flex' }}>
                          <FormControl
                            sx={{
                              '.Mui-disabled': {
                                WebkitTextFillColor:
                                  attendanceForCurrentSession?.status == 5
                                    ? `${colors.statusRecoveredText} !important`
                                    : attendanceForCurrentSession?.status == 6
                                      ? `${colors.statusMaterialText} !important`
                                      : ''
                              },
                              m: 1
                            }}
                            variant="standard"
                            disabled={isDisabledSessionBox}
                          >
                            <LazySelect
                              key={index}
                              labelId="demo-customized-select-label"
                              id="demo-customized-select"
                              value={
                                attendanceForCurrentSession
                                  ? attendanceForCurrentSession.status
                                  : 0
                              }
                              defaultValue={0}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 50
                                }
                              }}
                              sx={{
                                '.MuiSelect-iconOpen': { paddingLeft: '16px' },
                                '.MuiMenuItem-root.Mui-selected': {backgroundColor: '#F6F6F6'},
                                '.MuiSelect-icon ': {
                                  fill: colorAttendance,
                                  paddingRight: '16px'
                                },
                                color:
                                  session?.state &&
                                  user?.permission[PERMISSIONS.attendanceList]
                                    ? colors.gris05
                                    : colors.gris02
                              }}
                              IconComponent={KeyboardArrowDownIcon}
                              className={`table-attendance-student table-attendance-student__select 
                                             ${cssClassAttendance}
                                             ${
                                               session.state
                                                 ? ''
                                                 : 'table-attendance-student__sesion-disable-attendance'
                                             } 
                                             ${isDisabledSessionBox ? 'disable-box' : ''}
                                          `}
                              onChange={(event: SelectChangeEvent<number>) => {
                                if (attendanceForCurrentSession) {
                                  handleUpdateStateAttendance(
                                    event,
                                    attendanceForCurrentSession
                                  );
                                } else {
                                  handleSubmitUniqueAttendance(
                                    event,
                                    session.session_number,
                                    roomInfo.id,
                                    row.id
                                  );
                                }
                              }}
                              options={menuItemData}
                            />
                          </FormControl>
                          {isPossibleAddJustification ? (
                            <ButtonLineal
                              hasPadding={false}
                              underline={false}
                              size="small"
                              disabled={false}
                              onClick={() =>
                                showModalAddJustification(
                                  attendanceForCurrentSession?.absence_reason
                                    ?.id
                                    ? 'edit'
                                    : 'add',
                                  row,
                                  attendanceForCurrentSession
                                )
                              }
                            >
                              {attendanceForCurrentSession?.absence_reason
                                ?.id ? (
                                <>
                                  <IconOutlinedInformation fill="#313131" />
                                </>
                              ) : (
                                <div>
                                  <IconAddSymbol style={{ paddingLeft: 6 }} />
                                </div>
                              )}
                            </ButtonLineal>
                          ) : (user?.permission.name !== ADMIN &&
                              attendanceForCurrentSession?.absence_reason?.id &&
                              attendanceStatus === 3) ||
                            attendanceStatus === 4 ? (
                            <ButtonLineal
                              hasPadding={false}
                              underline={false}
                              size="small"
                              disabled={false}
                              onClick={() =>
                                showModalDetailJustification(
                                  attendanceForCurrentSession
                                )
                              }
                            >
                              <IconOutlinedInformation fill="#313131" />
                            </ButtonLineal>
                          ) : null}
                        </div>
                      </TableCell>
                    );
                  }
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ModalMessageAlert
        className="table-attendance-student__modal-alert"
        width="sm"
        iconAlert={<IconAlert fill={colors.warningColor} />}
        title={
          <Typography
            sx={{
              color: colors.gris05,
              fontFamily: 'Sofia Pro',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: 24,
              lineHeight: '32px',
              gap: 4,
              textAlign: 'center'
            }}
          >
            ¿Seguro que quieres borrar esta sesión?
          </Typography>
        }
        isOpenModal={isOpenModalDeleteSesion}
        openModal={openModalDeleteSesion}
        closeModal={closeModalDeleteSesion}
        body={
          <Typography
            sx={{
              color: colors.gris05,
              fontFamily: 'Sofia Pro',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 14,
              lineHeight: '20px',
              gap: 4,
              textAlign: 'center'
            }}
          >
            Si eliminas esta sesión, el curso tendrá{' '}
            <strong>{getActiveSessions(roomInfo?.date_session) - 1}</strong>{' '}
            sesiones.
          </Typography>
        }
        button1={
          <ButtonOutline onClick={closeModalDeleteSesion}>
            Cancelar
          </ButtonOutline>
        }
        button2={
          <ButtonFilled onClick={() => deleteSesion(selectSesion)}>
            Borrar sesión
          </ButtonFilled>
        }
      />
      <ModalMessageAlert
        className="table-attendance-student__modal-alert"
        width="sm"
        iconAlert={<IconAlert fill={colors.warningColor} />}
        title={
          <Typography
            sx={{
              color: colors.gris05,
              fontFamily: 'Sofia Pro',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: 24,
              lineHeight: '32px',
              gap: 4,
              textAlign: 'center'
            }}
          >
            ¿Seguro que quieres habilitar esta sesión?
          </Typography>
        }
        isOpenModal={isOpenModalAddSesion}
        openModal={openModalAddSesion}
        closeModal={closeModalAddSesion}
        body={
          <Typography
            sx={{
              color: colors.gris05,
              fontFamily: 'Sofia Pro',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 14,
              lineHeight: '20px',
              gap: 4,
              textAlign: 'center'
            }}
          >
            Si habilitas esta sesión, el curso tendrá{' '}
            <strong>{getActiveSessions(roomInfo?.date_session) + 1}</strong>{' '}
            sesiones.
          </Typography>
        }
        button1={
          <ButtonOutline onClick={closeModalAddSesion}>Cancelar</ButtonOutline>
        }
        button2={
          <ButtonFilled onClick={() => habilitarSesion(selectSesion)}>
            Habilitar sesión
          </ButtonFilled>
        }
      />
      <ModalInactiveStudentsReasons
        studentName={studentName}
        studentId={studentId}
        width="lg"
        isOpen={isOpenModalAddTeacherEtario}
        closeModal={closeModalAddTeacherEtario}
        reasonOptions={reasonOptions}
        refetch={refetch}
        roomId={roomInfo?.id}
        studentState={studentState}
      />
      <ModalInactiveStudent
        width="xs"
        title={`${studentName} está inactivo por:`}
        message={inactiveStudentReason}
        isOpenModal={isOpenModalActiveStudent}
        openModal={openModalActiveStudent}
        closeModal={closeModalActiveStudent}
        button1={
          <ButtonFilled
            fullWidth={true}
            size="default"
            onClick={closeModalActiveStudent}
          >
            Aceptar
          </ButtonFilled>
        }
        button2={
          user?.permission.name !== 'Teacher' ? (
            <ButtonLineal size="default" onClick={handleConfirm}>
              Volver a activar
            </ButtonLineal>
          ) : null
        }
      />
      <ModalInactiveStudentsReasons
        studentName={studentName}
        studentId={studentId}
        inactiveStudentReason={inactiveStudentReason}
        width="lg"
        isOpen={isOpenModalConfirmActive}
        closeModal={closeModalConfirmActive}
        reasonOptions={reasonOptions}
        refetch={refetch}
        roomId={roomInfo?.id}
        studentState={studentState}
      />
      <ModalJustification
        refetchAttendanceDetails={refetchAttendanceDetails}
        reasonAbsence={reasonAbsence}
        setReasonAbsence={setReasonAbsence}
        attendanceId={currentSessionId}
        student={currentStudent}
        listReason={listReasonAbsence}
        closeModal={closeModalAddJustification}
        isOpenModal={isOpenModalAddJustification}
        typeModal={typeModalJustification}
      />
      <Modal
        className="modal-detail-justification"
        width="sm"
        isOpen={isOpenModalDetailJustification}
        closeModal={closeModalDetailJustification}
        title={
          <NewTypography align="center" type="h1" variation="mobile" bold>
            Justificación de la falta:
          </NewTypography>
        }
        align="center"
        body={
          <NewTypography align="center" type="paragraph1" variation="mobile">
            {reasonAbsence?.name}
          </NewTypography>
        }
        buttons={
          <ButtonFilled
            fullWidth
            size="large"
            onClick={closeModalDetailJustification}
          >
            Aceptar
          </ButtonFilled>
        }
      />
    </>
  );
};

export default TableAttendanceStudentV2;
