/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Alert, AlertTitle } from '@material-ui/core';

import { Students } from './Students/Students';
import { Teachers } from './Teachers/Teachers';
import Evaluations from './Evaluations/Evaluations';
import CommentsSection from './Comments/CommentsSection';
import RoomDetailHeader from './Header/RoomDetailHeader';
import useRoomDetail from '../../../../hooks/useRoomDetail';
import Tabs from '../../../../components/molecules/Tabs/Tabs';
import Link from '../../../../components/molecules/Link/Link';
import IconArrow from '../../../../components/atoms//icons/IconArrow';
import NoAccess from '../../../../components/organism/NoAccess/NoAccess';
import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import { setRoom } from '../../../../slices/roomDetailSlice/roomDetailSlice';
import {
  AppDispatch,
  RootState,
  useAppDispatch
} from '../../../../store/store';
import { START_DATE_EVALUATIONS } from '../../../../constants/evaluationsDate.constants';
import {
  TEACHER_CTC,
  TEACHER_AUXILIARY
} from '../../../../constants/usersType.constants';

interface ITabsContent {
  headers?: string[];
  contents?: any;
}

function RoomDetailV2() {
  const { roomId } = useParams();
  const dispatch: AppDispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const [activeTabTeachers, setActiveTabTeachers] = useState<number>(0);
  const [tabsContentStudents, setTabsContentStudents] = useState<ITabsContent>({
    headers: [],
    contents: []
  });
  const [tabsContentTeachers, setTabsContentTeachers] = useState<ITabsContent>({
    headers: [],
    contents: []
  });
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);
  const [dateEvaluations, setDateEvaluations] = useState({});

  const refetchDetailRoom = () => {
    setShouldRefetch(!shouldRefetch);
  };

  const {
    data: dataRoom,
    isLoading,
    isError,
    error
  } = useRoomDetail({
    roomId: Number(roomId),
    shouldRefetch: shouldRefetch
  });

  useEffect(() => {
    if (dataRoom) {
      dispatch(setRoom(dataRoom));
      if (START_DATE_EVALUATIONS !== '') {
        setDateEvaluations(
          moment(dataRoom?.date_session[0]?.start_date).format('YYYY-MM-DD') >=
            moment(START_DATE_EVALUATIONS).format('YYYY-MM-DD')
        );
      }
      if (dataRoom?.business_type !== 'B2B') {
        setTabsContentStudents({
          headers: ['Asistencias', 'Evaluaciones'],
          contents: [
            <Students
              key="tab-content-students"
              refetch={refetchDetailRoom}
              room={dataRoom}
            />,
            <Evaluations
              key="tab-content-evaluations"
              refetch={refetchDetailRoom}
              room={dataRoom}
            />
          ]
        });
        setTabsContentTeachers({
          headers: ['Asistencias Crack the Code'],
          contents: [
            <Teachers
              refetch={refetchDetailRoom}
              room={dataRoom}
              type="teacherCTC"
            />
          ]
        });
      } else {
        setTabsContentStudents({
          headers: ['Asistencias'],
          contents: [
            <Students
              key="tab-content-students"
              refetch={refetchDetailRoom}
              room={dataRoom}
            />
          ]
        });
        if (user?.permission?.name !== TEACHER_AUXILIARY) {
          if (
            user?.permission?.name === TEACHER_CTC &&
            dataRoom.auxiliar_teacher.length > 0
          ) {
            setActiveTabTeachers(1);
          }
          setTabsContentTeachers({
            headers: ['Asistencias Crack the Code', 'Asistencias externos'],
            contents: [
              <Teachers
                refetch={refetchDetailRoom}
                room={dataRoom}
                type="teacherCTC"
              />,
              <Teachers
                refetch={refetchDetailRoom}
                room={dataRoom}
                type="AuxiliaryTeacher"
              />
            ]
          });
        } else {
          setTabsContentTeachers({
            headers: ['Asistencias externos'],
            contents: [
              <Teachers
                refetch={refetchDetailRoom}
                room={dataRoom}
                type="AuxiliaryTeacher"
              />
            ]
          });
        }
      }
    }
  }, [dataRoom]);

  if (isLoading) return <ScreenLoader />;
  if (
    isError &&
    error?.code === 403 &&
    (user?.permission?.name === TEACHER_CTC ||
      user?.permission?.name === TEACHER_AUXILIARY)
  ) {
    return (
      <NoAccess
        button={{
          to: '/dashboard/rooms',
          message: 'Volver a salones'
        }}
        errorMessage="No tienes acceso a esta sección"
      />
    );
  }
  if (isError || !dataRoom) {
    return (
      <>
        <Link
          className="room-detail-header-v2__button-back"
          iconLeft={<IconArrow className="room-detail-header-v2__icon-back" />}
          to="/dashboard/rooms"
        >
          Regresar a Salones
        </Link>

        <Alert
          severity="error"
          variant="outlined"
          action={
            <Button color="inherit" size="small" onClick={refetchDetailRoom}>
              Reintentar
            </Button>
          }
        >
          <AlertTitle>Error</AlertTitle>
          No se pudo obtener la información del salón. Por favor reintente.
        </Alert>
      </>
    );
  }

  return (
    <>
      <RoomDetailHeader room={dataRoom} refetch={refetchDetailRoom} />
      <h1 className="room-details-section-title">Estudiantes</h1>
      {dateEvaluations ? (
        <Tabs
          width={300}
          tabHeaders={tabsContentStudents.headers}
          tabContents={tabsContentStudents.contents}
        />
      ) : (
        <Students refetch={refetchDetailRoom} room={dataRoom} />
      )}
      <h1 className="room-details-section-title">Profesores</h1>
      <Tabs
        width={300}
        tabHeaders={tabsContentTeachers.headers}
        tabContents={tabsContentTeachers.contents}
        activeTab={activeTabTeachers}
        setActiveTab={setActiveTabTeachers}
      />
      <CommentsSection
        refetchRoomDetails={refetchDetailRoom}
        roomInfo={dataRoom}
      />
    </>
  );
}

export default RoomDetailV2;
