import { useState } from 'react';
import { Formik, Form } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { Input, Stack } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

import PERMISSIONS from '../../../../constants/permission.constants';
import Paragraph from '../../../../components/common/Paragraph/Paragraph';
import {
  AppDispatch,
  RootState,
  useAppDispatch
} from '../../../../store/store';
import {
  getAllRooms,
  updateRoom
} from '../../../../slices/roomSlice/roomsSlice';
import IconPencilFilled from '../../../../components/atoms/icons/IconPencilFilled';

interface Props {
  roomId: number;
  url_satisfaction_survey: string;
  refetch: () => void;
}

const EditUrlSatisfaction: React.FC<Props> = ({
  roomId,
  url_satisfaction_survey,
  refetch
}) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const dispatch: AppDispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.auth.user);

  const editRoom = async (room: any) => {
    try {
      await dispatch(updateRoom({ roomId, room }));
    } catch (error) {
      console.log(error);
    } finally {
      if (user) {
        const userId = user?.permission[PERMISSIONS.roomListAll] ? 0 : user?.id;
        await dispatch(getAllRooms({ userId, currentPage: 1, size: 100 }));
      }
    }
  };

  const handleEdit = () => setIsEdit(true);
  const handleCancel = () => setIsEdit(false);

  const initialValues = { url_satisfaction_survey: url_satisfaction_survey };

  return (
    <>
      {isEdit ? (
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={async values => {
              const data = {url_satisfaction_survey: values.url_satisfaction_survey || ' '};
              await editRoom(data);
              refetch();
              setIsEdit(false);
            }}
          >
            {({ values, handleChange }) => (
              <Form>
                <Stack direction="row" columnGap={1}>
                  <Input
                    id="url_satisfaction_survey"
                    value={values.url_satisfaction_survey}
                    onChange={handleChange}
                  />
                  <LoadingButton
                    color="primary"
                    type="submit"
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                  >
                    Guardar
                  </LoadingButton>
                  <LoadingButton
                    color="error"
                    onClick={handleCancel}
                    loadingPosition="start"
                    startIcon={<CancelIcon />}
                    variant="contained"
                  >
                    Cancelar
                  </LoadingButton>
                </Stack>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <>
          <IconPencilFilled
            onClick={handleEdit}
            style={{ paddingLeft: '8px' }}
          />
          <Paragraph
            text={url_satisfaction_survey}
            copyable
            hideText
            direction="right"
          />
        </>
      )}
    </>
  );
};

export default EditUrlSatisfaction;
